import { mapState } from "vuex";

export default {
  provide() {
    return {
      uid: this.uid,
      title: this.title,
      pageType: this.pageType,
      metaTitle: this.metaTitle,
      collectionType: `all${this.pageType}s`,
      blogTags: this.blogTags ?? [],
    };
  },

  computed: {
    ...mapState("app", ["redirectFrom"]),
    placement() {
      switch (this.pageType) {
        case "Homepage":
          return "home-page";
        case "Product_detail_page":
          return "product-page";
        case "Cart":
          return "basket-page";
        default:
          return "other";
      }
    },
    seed() {
      return this.sliceContext?.cartSkus
        ? this.sliceContext?.cartSkus
        : this.sliceContext?.sku
        ? [this.sliceContext?.sku]
        : [];
    },
  },

  created() {
    this.$store.dispatch("recommendations/init", {
      placement: this.placement,
      seed: this.seed,
    });
  },

  beforeMount() {
    const origin = window?.location.origin;
    const referrer = document?.referrer;
    const isNavigate =
      performance?.getEntriesByType("navigation")[0]?.type === "navigate";
    const pageReferrer = this.redirectFrom?.url
      ? this.redirectFrom?.url === "from search"
        ? `${origin}/products/search`
        : `${origin}${this.redirectFrom?.url}`
      : isNavigate
      ? referrer
      : window?.location.href;

    this.$gtm.gtagEvent("virtPath", {
      virtPath: this.$router?.history?.current?.path,
      virtTitle: this.metaTitle ?? "",
      page_location: `${origin}${this.$router?.history?.current?.fullPath}`,
      page_referrer: pageReferrer,
    });
  },

  mounted() {
    this.$gtm.push({ ecommerce: null });
  },

  beforeRouteLeave(to, from, next) {
    const title = this.title?.[0] ?? "";
    if (title) {
      const payload = { title, url: from.fullPath };
      this.$store.dispatch("app/setRedirectFrom", payload);
    }
    this.$store.dispatch("app/closeProductQuickview");
    this.$store.dispatch("app/closeAddedToBag", this.isTablet);
    next();
  },

  watch: {
    "$route.fullPath": {
      immediate: true,
      handler(value) {
        if (process.server) {
          return;
        }

        setTimeout(() => {
          this.$dotdigital.track({
            ...this.dotDigitalProduct,
            page_title: this.metaTitle,
          });
        }, 500);
      },
    },
  },
};
