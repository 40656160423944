module.exports = {
  colors: {
    primary: "#D8CFC5",
    black: "#000000",
    white: "#FFFFFF",
    green: {
      400: "#A3D783",
      500: "#77bc1f",
      600: "#02555F",
      900: "#183029",
    },
    red: {
      700: "#CC2348",
      800: "#D6083B",
    },
    gray: {
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#EFE9E5",
      333: "#333333",
      400: "#D8CFC5",
      450: "#4f4f4f",
      500: "#BFB6AD",
      555: "#555555",
      600: "#828282",
      "6C6": "#6C6C6C",
      616: "#616161",
      700: "#52555C",
      767: "#767779",
      800: "#222222",
      900: "#302E2F",
      950: "#959595",
      1000: "#E9E9E9",
      1100: "#EBEBEB",
      1200: "#1F1F1F",
      1300: "#BFBFBF",
      1400: "#BDBDBD",
      1500: "#3B3838",
      1600: "#C4C4C4",
      1700: "#212121",
      1800: "#2d2d2d",
      1900: "#757575",
      2000: "#1a202c",
      2100: "#E0E0E0",
      2200: "#424242",
      2300: "#E5E5E5",
      2400: "#F1F2F4",
    },
    brown: {
      50: "#C9C4BF",
      100: "#9E785B",
      200: "#7E543A",
      300: "#64433D",
      400: "#562E1D",
      500: "#64443f",
      600: "#B5AC9F",
      767: "#767779",
      800: "#63433C",
      E4E: "#E4E2DF",
    },
    teal: {
      800: "#00555F",
    },
    blue: {
      100: "#D9ECED",
      200: "#E5F1F5",
    },
    campaign: {
      sale: "#CC2348",
      defaultBg: "#CC2348",
      clearance: "#FEBD40",
      autoclub: "#00ACA0",
      member: "#00555F",
      velocity: "#D6083B",
    },
    banner: "#5D7389",
    workwithus: {
      title: "#654F3F",
      gray: "#D4D4D4",
      brown90: "#562E1DED",
      baige80: "#EFE9E5CC",
      baige00: "#EFE9E500",
    },
  },
};
