import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
} from "apollo-cache-inmemory";

import PrismicLink from "~/app/prismic/prismic-link";
import errorLink from "~/utils/apollo-error-link";
import introspectionQueryResultData from "~/prismic-fragment-types.json";

// prismic graphql adapter
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export default function ({ $config, $bugsnag }) {
  const { prismicGraphqlURL, prismicAPIEndPoint } = $config;

  return {
    defaultHttpLink: false,
    link: errorLink("Prismic", $bugsnag).concat(
      PrismicLink({ uri: prismicGraphqlURL, apiEndpoint: prismicAPIEndPoint })
    ),
    cache: new InMemoryCache({ fragmentMatcher }),
  };
}
