import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

function removeWhiteSpace(str) {
  const regexp = /(%0A|%20)*(%20|%7B|%7D)(%0A|%20)*/g;

  const [path, query] = str.split("?");
  if (!query) return str;

  const shortQuery = query
    .split("&")
    .map(param => {
      const [name, value] = param.split("=");
      if (name === "query") {
        return (
          name +
          "=" +
          value.replace(regexp, (chars, spaces, brackets) => brackets)
        );
      }
      return param;
    })
    .join("&");

  return [path, shortQuery].join("?");
}

function PrismicLink({ uri, apiEndpoint, accessToken, ...options }) {
  const prismicLink = setContext((request, previousContext) => {
    return import("@prismicio/client")
      .then(({ default: Prismic }) =>
        Prismic.client(apiEndpoint, { accessToken }).getApi()
      )
      .then(api => ({
        headers: {
          "Prismic-ref": api.masterRef.ref,
          integrationFieldRef: api?.data?.integrationFieldsRef ?? null,
          ...previousContext.headers,
          ...(accessToken ? { Authorization: `Token ${accessToken}` } : {}),
        },
      }));
  });

  const httpLink = new HttpLink({
    uri,
    useGETForQueries: true,
    fetch: (url, options) => {
      const {
        headers: { integrationFieldRef, ...restHeaders },
        ...restOptions
      } = options;

      const integrationFieldRefQuery = integrationFieldRef
        ? `&integrationFieldRef=${integrationFieldRef}`
        : "";

      const trimmed = removeWhiteSpace(url);
      const updatedOptions = { headers: { ...restHeaders }, ...restOptions };
      return fetch(`${trimmed}${integrationFieldRefQuery}`, updatedOptions);
    },
    ...options,
  });

  return prismicLink.concat(httpLink);
}

export default PrismicLink;
