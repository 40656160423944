import { gql } from "graphql-tag";

export const documentLink = gql`
  fragment documentLink on _Linkable {
    _linkType
    ... on _Document {
      _meta {
        uid
        id
        type
      }
    }
  }
`;

export const externalLink = gql`
  fragment externalLink on _Linkable {
    _linkType
    ... on _ExternalLink {
      url
      target
    }
  }
`;

export const collectionLink = gql`
  fragment collectionLink on Collection_page {
    body {
      ... on Collection_pageBodyHero_banner_v2 {
        variation {
          ... on Collection_pageBodyHero_banner_v2Default {
            primary {
              title
              paragraph
              banner_bg_image
            }
          }
        }
      }
    }
  }
`;
