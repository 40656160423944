async function _getShopifyData({ query, variables, client, fetchPolicy }) {
  try {
    const response = await client.query({
      query,
      variables,
      ...(fetchPolicy && { fetchPolicy }),
    });
    return response;
  } catch (err) {
    console.error(err);
    throw new Error("Oops Something went wrong...", { cause: err });
  }
}

async function _setShopifyData({ mutation, variables, client, fetchPolicy }) {
  try {
    const response = await client.mutate({
      mutation,
      variables,
      ...(fetchPolicy && { fetchPolicy }),
    });
    return response;
  } catch (err) {
    console.error(err);
    throw new Error("Oops Something went wrong...", { cause: err });
  }
}

export default function ({ app }, inject) {
  const client = app.apolloProvider.defaultClient;

  const getShopifyData = ({ query, variables = {}, fetchPolicy }) =>
    _getShopifyData({ query, variables, client, fetchPolicy });

  const setShopifyData = ({ mutation, variables = {} }) =>
    _setShopifyData({ mutation, variables, client });

  inject("getShopifyData", getShopifyData);
  inject("setShopifyData", setShopifyData);
}
