export default async function ({ app, route, redirect, error }) {
  let data = null;
  try {
    const encodedFrom = encodeURIComponent(route.fullPath);
    ({ data } = await app.$axios.get(`api/seo-data?from=${encodedFrom}`));
  } catch (err) {
    console.error(err);
    return;
  }

  if (!data.redirect && !data.resourceGone) {
    return;
  }

  if (data.resourceGone) {
    const { message } = data.resourceGone;
    error({ statusCode: 410, message });
    return;
  }

  if (data.redirect) {
    const { to, statusCode } = data.redirect;
    // If it exists, redirect the page with a statusCode response else carry on
    if (to) {
      redirect(statusCode, to);
    }
  }
}
