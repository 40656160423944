
import { mapState } from "vuex";

export default {
  props: {
    hasChildren: { type: Boolean, required: true },
    index: { type: Number, default: -1 },
    link: { type: Object, default: () => {} },
    linkLabel: { type: Array, default: () => [] },
    linkColor: { type: String, default: "" },
  },

  data: () => ({ timerObj: null }),

  computed: {
    ...mapState("app", ["activeMenuIndex"]),
  },

  methods: {
    closeMenuFirstScreenMobile() {
      this.$store.dispatch("app/closeMegaMenu");
    },
    toggleChildren() {
      this.closeMenuFirstScreenMobile();
      if (this.hasChildren) {
        this.$store.dispatch("app/setActiveMenuIndex", this.index);
      } else {
        this.$router.push(this.$prismic.asLink(this.link));
      }
    },
  },
};
