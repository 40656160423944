import { gql } from "graphql-tag";

export const getSeoFields = pageType => gql`
  fragment ${pageType}SEOFields on ${pageType} {
    metaTitle: meta_title
    metaDescription: meta_description
    metaRobots: meta_robots
  }
`;

export const getMetaFields = pageType => gql`
  fragment ${pageType}MetaFields on ${pageType} {
    _linkType
    _meta {
      id
      uid
      type
    }
  }
`;

export default {
  head() {
    const additionalLinks = this.additionalLinks ?? [];
    const additionalScripts = this.additionalScripts ?? [];

    return {
      title: this.metaTitle || "",
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.metaDescription || "",
        },
        {
          hid: "robots",
          name: "robots",
          content: this.metaRobots || "follow, index",
        },
      ],
      link: [
        {
          rel: "canonical",
          href:
            this.$prismic.asLink({
              ...this.metaFields,
              fromCanonical: true,
              page: this.$route.query.page,
            }) || "/",
        },
        ...additionalLinks,
      ],
      script: [...additionalScripts],
    };
  },
};
