import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("app", ["windowWidth"]),

    isMobile() {
      return this.isDevice(767);
    },
    isTablet() {
      return this.isDevice(1200);
    },
    isTabletAndPortrait() {
      return this.isDevice(800);
    },
    isMobileOrTablet() {
      return this.isMobile || this.isTablet;
    },
    isDesktop() {
      return this.isDevice(1900);
    },
    isMyDesktop() {
      if (!this.$device.isMacOS) {
        return this.$device.isDesktop;
      }

      if (typeof window == "undefined") {
        console.error(
          "since useragent is not working properly in macOs's It will work based on window width and here window is undefined and it also means you are using this in SSR mode for which it will not work properly"
        );
        return this.$device.isDesktop;
      }

      const width = 1900;
      const windowWidth = this.windowWidth ?? window.outerWidth;
      return windowWidth >= width;
    },
  },
  methods: {
    isDevice(width) {
      const platform = this.$store.state.platform;
      // ssr handling
      if (typeof window == "undefined") {
        if (platform == "mobile") {
          return true;
        } else {
          return false;
        }
      }

      // else depend on window width
      let windowWidth = this.windowWidth;

      if (!windowWidth) {
        windowWidth = window.outerWidth;
      }

      return windowWidth <= width;
    },
  },
};
