export const state = () => ({
  variantLoading: false,
  processing: false,
  bottomline: null,
  reviews: [],
  mattressProtector: null,
});

export const actions = {
  async getReviewRating({ commit, rootState }, { productId }) {
    const reviewsPerPage = rootState.siteConfig.reviewsPerPage;
    const url = this.$config.yotpoReviewUrl.replace("$productId", productId);

    try {
      await this.$axios
        .get(`${url}?page=1&per_page=${reviewsPerPage}`)
        .then(({ data }) => {
          commit("setReviewRating", { ...data.response });
        });
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },
  setVariantLoading({ commit }) {
    commit("SET_VARIANT_LOADING", true);
  },
  resetVariantLoading({ commit }) {
    commit("SET_VARIANT_LOADING", false);
  },
  setMattressProtector({ commit }, payload) {
    commit("SET_MATTRESS_PROTECTOR", payload);
  },
};

export const mutations = {
  setReviewRating(state, { bottomline, reviews }) {
    state.bottomline = bottomline;
    state.reviews = reviews;
  },

  SET_VARIANT_LOADING(state, status) {
    state.variantLoading = status;
  },

  SET_MATTRESS_PROTECTOR(state, payload) {
    state.mattressProtector = payload;
  },
};
