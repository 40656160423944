
export default {
  props: {
    field: {
      required: true,
      validator: v => v == null || typeof v == "object",
    },
    isLinkOptional: { type: Boolean, default: false },
  },
};
