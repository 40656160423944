
import { mapState } from "vuex";

export default {
  props: {
    hierarchicalMenu: { type: Array, default: () => [] },
    mobileBanner: { type: Object, default: () => {} },
    mobileBannerLink: { type: Object, default: () => {} },
  },

  computed: {
    ...mapState("app", ["activeMenuIndex", "isMegaMenuOpen"]),
    showMobileBanner() {
      return this.$device.isMobileOrTablet && this.isMegaMenuOpen;
    },
    menuItems() {
      return this.hierarchicalMenu.map(({ collection, hasChildren }) => ({
        ...collection.primary,
        hasChildren,
      }));
    },
  },

  watch: {
    isMegaMenuOpen(newVal, oldVal) {
      if (!newVal) {
        this.$refs["mega-menu-top"].scrollTo({
          top: 0,
          left: 0,
        });
      }
    },
  },
};
