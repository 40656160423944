import { evolve, map } from "ramda";

import { getVariant } from "./normalize-product";

/**
 *  filters out those line items whose corresponding product has been archived/
 * delete or drafted or removed from sales channel
 */
const normalizeCart = evolve({
  totalPrice: cost => cost?.totalPrice?.amount,
  lines: {
    nodes: map(({ lineComponents, ...rest }) => {
      return {
        ...evolve({ variant: getVariant }, rest),
        lineComponents: map(
          evolve({ variant: getVariant }),
          lineComponents ?? []
        ),
      };
    }),
  },
});

export default normalizeCart;
