function gtmClient(ctx, enabled) {
  return {
    gtagEvent(eventName, payload) {
      this.gtag("event", eventName, payload);
    },
    gtag(...args) {
      if (enabled && window?.gtag) {
        window.gtag(...args);
      }
    },
    push(...args) {
      if (enabled && window?.dataLayer) {
        window.dataLayer.push(...args);
      }
    },
  };
}

function gtmServer(ctx, enabled) {
  const events = [];

  ctx.beforeNuxtRender(() => {
    console.log({ events });
    if (!events.length) {
      return;
    }

    const gtmScript = ctx.app.head.script.find(
      s => s.hid == "gtm-script-id"
    );
    gtmScript.innerHTML = `window['dataLayer']=${JSON.stringify(events)};${
      gtmScript.innerHTML
    }`;
  });

  return {
    gtagEvent(eventName, payload) {
      this.gtag("event", eventName, payload);
    },
    gtag(...args) {
      if (enabled) {
        events.push(...args);
      }
    },
    push(...args) {
      this.gtag(args);
    },
  };
}

export default (ctx, inject) => {
  const enabled = "true";
  const $gtm = process.client
    ? gtmClient(ctx, enabled)
    : gtmServer(ctx, enabled);

  inject("gtm", $gtm);
};
