export const state = () => ({
  initialized: false,
  postcode: null,
  eligible: false,
  regionCode: null,
  postcodeCutOffTime: null,
  postcodeDeliveryDay: null,
  postcodeWarehouseId: null,
  items: [],
  availableSkus: [],
  lowStockSkus: [],
  insufficientStockSkus: [],
  suburb: null,
});

export const getters = {
  hasQuickshipItems(state) {
    return state.items.length > 0;
  },
  // Return true if the order is only partially made up of Quickship items
  hasPartialQuickshipItems: (state, getters, rootState, rootGetters) => {
    const quickshipItemsLen = state.items.length;
    const visibleLineItemsLen = rootGetters["bag/visibleLineItems"].length;

    return quickshipItemsLen && visibleLineItemsLen !== quickshipItemsLen;
  },
};

export const actions = {
  async init({ commit, dispatch, rootGetters }) {
    const quickshipAttrs =
      rootGetters["bag/cartAttributes"]?._quickship_attrs?.value;

    let parsedValue = quickshipAttrs ? JSON.parse(quickshipAttrs) : {};
    if (parsedValue.expiresAt && new Date(parsedValue.expiresAt) < new Date()) {
      parsedValue = {};
    }

    dispatch("setPostcode", {
      postcode: parsedValue?.quickshipPostcode ?? null,
    });

    dispatch("setEligible", {
      eligible: parsedValue?.quickshipEligible ?? false,
    });

    dispatch("setRegionCode", {
      regionCode: parsedValue?.quickshipRegionCode ?? null,
    });

    dispatch("setPostcodeCutOffTime", {
      postcodeCutOffTime: parsedValue?.quickshipPostcodeCutOffTime ?? null,
    });

    dispatch("setPostcodeDeliveryDay", {
      postcodeDeliveryDay: parsedValue?.quickshipPostcodeDeliveryDay ?? null,
    });

    dispatch("setPostcodeWarehouseId", {
      postcodeWarehouseId: parsedValue?.quickshipPostcodeWarehouseId ?? null,
    });

    dispatch("setSuburb", { suburb: parsedValue?.suburb ?? null });

    dispatch(
      "bag/selectShippingRate",
      { shippingRate: parsedValue?.shippingRate ?? null },
      { root: true }
    );

    if (parsedValue.quickshipPostcodeWarehouseId) {
      await dispatch("resolveQuickshipItems");
    }

    commit("markInitialized");
  },
  async checkPostcode({ dispatch }, { postcode }) {
    try {
      const { data } = await this.$axios.get(
        `${this.$config.snoozeEcom}/quickship/eligible-postcode?postcode=${postcode}`
      );
      const {
        eligible,
        state: regionCode,
        cutoff_at: postcodeCutOffTime,
        delivery_at: postcodeDeliveryDay,
        warehouse_id: postcodeWarehouseId,
      } = data;

      dispatch("setEligible", { eligible });
      dispatch("setRegionCode", { regionCode });
      dispatch("setPostcodeCutOffTime", { postcodeCutOffTime });
      dispatch("setPostcodeDeliveryDay", { postcodeDeliveryDay });
      dispatch("setPostcodeWarehouseId", { postcodeWarehouseId });
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },
  async resolveQuickshipItems({ commit, rootGetters, rootState }) {
    try {
      const skus = rootGetters["bag/productSkus"].join(",");
      const quantities = rootGetters["bag/productQuantities"].join(",");
      const wareHouseId = rootState.quickship.postcodeWarehouseId;
      const url = `${this.$config.snoozeEcom}/stock?skus=${skus}&warehouse_id=${wareHouseId}&quantities=${quantities}`;
      const { data } = await this.$axios.get(url);

      const availableItems = (data ?? []).filter(item => item.available);
      commit("setItems", { items: availableItems.map(item => item.sku) });

      const lowStockSkus = availableItems
        .filter(item => item.low_stock && !item.insufficient_stock)
        .map(item => item.sku);
      commit("setLowStockSkus", { lowStockSkus });

      const insufficientStockSkus = availableItems
        .filter(item => item.insufficient_stock)
        .map(item => item.sku);
      commit("setInsufficientStockSkus", { insufficientStockSkus });
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },
  setPostcode({ commit }, { postcode }) {
    commit("SET_POSTCODE", { postcode });
  },
  setEligible({ commit }, { eligible }) {
    commit("SET_ELIGIBLE", { eligible });
  },
  setRegionCode({ commit }, { regionCode }) {
    commit("SET_REGION_CODE", { regionCode });
  },
  setPostcodeCutOffTime({ commit }, { postcodeCutOffTime }) {
    commit("SET_POSTCODE_CUT_OFF_TIME", { postcodeCutOffTime });
  },
  setPostcodeDeliveryDay({ commit }, { postcodeDeliveryDay }) {
    commit("SET_POSTCODE_DELIVERY_DAY", { postcodeDeliveryDay });
  },
  setPostcodeWarehouseId({ commit }, { postcodeWarehouseId }) {
    commit("SET_POSTCODE_WAREHOUSE_ID", { postcodeWarehouseId });
  },
  async addQuickshipAttrsToCheckout({ dispatch, state, rootState }) {
    const quickshipAttrs = {
      quickshipPostcode: state.postcode,
      quickshipRegionCode: state.regionCode,
      quickshipEligible: state.eligible,
      quickshipPostcodeCutOffTime: state.postcodeCutOffTime,
      quickshipPostcodeDeliveryDay: state.postcodeDeliveryDay,
      quickshipPostcodeWarehouseId: state.postcodeWarehouseId,
      suburb: state.suburb,
      hasAssembly: rootState.bag.hasAssembly,
      hasFilteredQuickship: rootState.bag.hasFilteredQuickship,
      shippingRate: rootState.bag.selectedShippingRate,
      expiresAt: new Date().getTime() + 3600000, // 1 hour,
    };

    await dispatch(
      "bag/UPDATE_ATTRIBUTES",
      [{ key: "_quickship_attrs", value: JSON.stringify(quickshipAttrs) }],
      { root: true }
    );
  },
  setSuburb({ commit }, { suburb }) {
    commit("SET_SUBURB", { suburb });
  },
};

export const mutations = {
  setItems(state, { items }) {
    state.items = items;
  },
  setLowStockSkus: (state, { lowStockSkus }) => {
    state.lowStockSkus = lowStockSkus;
  },
  setInsufficientStockSkus: (state, { insufficientStockSkus }) => {
    state.insufficientStockSkus = insufficientStockSkus;
  },
  SET_POSTCODE(state, { postcode }) {
    state.postcode = postcode;
  },
  SET_ELIGIBLE(state, { eligible }) {
    state.eligible = eligible;
  },
  SET_REGION_CODE(state, { regionCode }) {
    state.regionCode = regionCode;
  },
  SET_POSTCODE_CUT_OFF_TIME(state, { postcodeCutOffTime }) {
    state.postcodeCutOffTime = postcodeCutOffTime;
  },
  SET_POSTCODE_DELIVERY_DAY(state, { postcodeDeliveryDay }) {
    state.postcodeDeliveryDay = postcodeDeliveryDay;
  },
  SET_POSTCODE_WAREHOUSE_ID(state, { postcodeWarehouseId }) {
    state.postcodeWarehouseId = postcodeWarehouseId;
  },
  SET_SUBURB(state, { suburb }) {
    state.suburb = suburb;
  },
  markInitialized(state) {
    state.initialized = true;
  },
};
