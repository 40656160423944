
import { mapState } from "vuex";

export default {
  props: { header: { type: Object, default: () => {} } },

  data: () => ({ menuTimer: null, miniBagTimer: null }),

  computed: {
    ...mapState(["siteConfig"]),
    ...mapState("app", [
      "isSearchOpen",
      "isMegaMenuOpen",
      "activeMenuIndex",
      "isMiniBagOpen",
    ]),
    isChildMenuOpened() {
      return (
        this.activeMenuIndex > -1 &&
        (this.$device.isMobileOrTablet || this.isTablet)
      );
    },
    isChildMenuClosed() {
      return this.activeMenuIndex == -1;
    },
    hierarchicalMenu() {
      const menuItems = this.header?.mega_menu_links ?? [];
      let results = [];

      for (const menuItem of menuItems) {
        // prepare data for L1 categories and banners
        results.push({
          collection: {
            primary: {
              link: menuItem.link,
              link_label: menuItem.link_label,
              link_color: menuItem.link_color,
            },
          },
          hasChildren: menuItem?.second_level_menu?.body.length > 0,
        });
      }

      return results;
    },
    logoStyle() {
      return this.$device.isMobileOrTablet && this.isMegaMenuOpen;
    },
    logoImgProps() {
      return {
        src: this.header?.site_logo?.url,
        alt: this.header?.site_logo?.alt,
        width: this.header?.site_logo?.dimensions.width,
        height: this.header?.site_logo?.dimensions.height,
      };
    },
  },

  watch: {
    $route() {
      this.closeDesktopMegaMenu();
    },
    isMegaMenuOpen(isFirstScreeOpened) {
      if (this.$device.isMobileOrTablet || this.isTablet) {
        this.$store.commit(
          "SET_TOGGLE_OVERFLOW_HIDDEN_BODY",
          isFirstScreeOpened,
          {
            root: true,
          }
        );
      }
    },
    activeMenuIndex(newVal) {
      if (this.$device.isMobileOrTablet || this.isTablet) {
        this.$store.commit(
          "SET_TOGGLE_OVERFLOW_HIDDEN_BODY",
          newVal > -1 && !this.isMegaMenuOpen,
          {
            root: true,
          }
        );
      }
    },
  },

  mounted() {
    // cancel menu close event mouse leave and enter again on L1 link
    this.$nuxt.$on("L1MouseEnter", this.onKeepMenuOpen);
  },

  methods: {
    onMouseLeave() {
      this.menuTimer = setTimeout(() => {
        this.closeDesktopMegaMenu();
      }, 500);
    },
    onKeepMenuOpen() {
      clearTimeout(this.menuTimer);
    },
    async onCartIconMouseEnterHandler() {
      this.onKeepMiniBagModalOpen();
      this.$store.dispatch("app/openMiniBag");
      await this.$store.dispatch("app/closeAddedToBag");
    },
    onCartIconMouseLeaveHandler() {
      this.miniBagTimer = setTimeout(() => {
        this.$store.dispatch("app/closeMiniBag");
      }, 500);
    },
    openMegaMenu() {
      this.$store.dispatch("app/showMegaMenu");
    },
    backToMainMenu() {
      this.$store.dispatch("app/setActiveMenuIndex", -1);
      this.openMegaMenu();
    },
    closeDesktopMegaMenu() {
      if (this.activeMenuIndex >= 0) {
        this.$store.dispatch("app/setActiveMenuIndex", -1);
      }
    },
    onKeepMiniBagModalOpen() {
      clearTimeout(this.miniBagTimer);
    },
  },
};
