
import { mapState } from "vuex";
import SwiperClass, { Pagination, Autoplay } from "swiper";

export default {
  props: {
    banners: { type: Array, default: () => [] },
    fontSize: { type: [Number, String], default: "" },
    textColor: { type: String, default: "" },
    bannerColor: { type: String, default: "" },
    timeInterval: { type: Number, default: 0 },
  },

  data: () => ({ showTopBanner: true }),

  computed: {
    ...mapState("app", ["windowWidth"]),
    normalizedBanners() {
      return this.banners.filter(
        item => this.$prismic.asText(item.banner_text)?.length
      );
    },
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        autoplay: {
          delay: this.timeInterval,
          disableOnInteraction: false,
        },
        loop: true,
        modules: [Pagination, Autoplay],
      };
    },
  },

  watch: {
    windowWidth: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        this.$store.dispatch(
          "app/setTopBannerHeight",
          this.$refs["top-banner"].clientHeight
        );
      },
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      ".swiper.information-banner",
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },

  methods: {
    closeTopBanner() {
      this.$store.dispatch("app/setTopBannerHeight", 0);
      this.showTopBanner = false;
    },
  },
};
