export default ({ redirect }) => {
  window.addEventListener(
    "click",
    event => {
      if (event.target.matches("a[data-nuxt-link]")) {
        // Don't follow the link
        event.preventDefault();

        // Push link destination to router
        redirect(event.target.pathname);
      }
    },
    false
  );
};
