import { gql } from "graphql-tag";

export const getProductCatalogueV2 = (pageType, isNewSliceType = false) => {
  const typeprefix = `${pageType}${isNewSliceType ? "Slices" : "Body"}`;

  return gql`
    fragment ${pageType}_product_catalogue_v2 on ${typeprefix}Product_catalogue_v2 {
      type
      variation {
        variation: __typename
        ... on ${typeprefix}Product_catalogue_v2Default {
          primary @skip(if: $lazy) {
            title
            max_products_limit
            list_type
          }
          items @include(if: $lazy) {
            product
          }
        }
        ... on ${typeprefix}Product_catalogue_v2Optimizely {
          primary @skip(if: $lazy) {
            title
            max_products_limit
            list_type
            widget
          }
        }
        ... on ${typeprefix}Product_catalogue_v2Searchspring {
          primary @skip(if: $lazy) {
            title
            max_products_limit
            list_type
            tag
          }
        }
        ... on ${typeprefix}Product_catalogue_v2Variant {
          primary @skip(if: $lazy) {
            title
            max_products_limit
            list_type
          }
          items @include(if: $lazy) {
            variant
          }
        }
      }
    }`;
};
