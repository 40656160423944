export const state = () => ({
  productsFetchingStatus: {},
  recommendedProduct: null,
});

export const getters = {
  getProductFetchingStatus(state) {
    return productHandle => state.productsFetchingStatus[productHandle] ?? true;
  },
};

export const actions = {
  setRecommendedProduct({ commit }, product) {
    commit("setRecommendedProduct", product);
  },
  setProductsFetching({ commit }, { productHandles, isFetching }) {
    productHandles.forEach(productHandle => {
      commit("setProductsFetchingStatus", {
        productHandle,
        isFetching,
      });
    });
  },
};

export const mutations = {
  setProductsFetchingStatus(state, { productHandle, isFetching }) {
    state.productsFetchingStatus = {
      ...state.productsFetchingStatus,
      [productHandle]: isFetching,
    };
  },
  setRecommendedProduct(state, product) {
    state.recommendedProduct = product;
  },
};
