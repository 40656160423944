
import { mapState } from "vuex";

export default {
  props: {
    hasChildren: { type: Boolean, required: true },
    index: { type: Number, default: -1 },
    link: { type: Object, default: () => {} },
    linkLabel: { type: Array, default: () => [] },
    linkColor: { type: String, default: "" },
  },

  data: () => ({ timerObj: null }),

  computed: mapState("app", ["activeMenuIndex"]),

  methods: {
    gtmHandler() {
      this.$gtm.push({
        event: "gaEvent",
        eventCategory: "Main Navigation",
        eventAction: this.$prismic.asText(this.linkLabel),
        eventLabel: undefined,
      });
    },
    onMouseEnter() {
      // emit event to cancel menu close event, if mouse leave and then enter again on L1 link
      // to keep mega menu opened
      if (this.hasChildren) {
        this.$nuxt.$emit("L1MouseEnter");

        this.timerObj = setTimeout(() => {
          this.$store.dispatch("app/setActiveMenuIndex", this.index);
          this.$emit("onMouseEnter");
        }, 300);
      }
    },
    onMouseLeave() {
      if (this.hasChildren) {
        clearTimeout(this.timerObj);
        this.$emit("onMouseLeave");
      }
    },
  },
};
