import { onError } from "@apollo/client/link/error";

const errorLink = (identifier, $bugsnag) =>
  onError(err => {
    const { graphQLErrors, networkError } = err;
    if (graphQLErrors) {
      $bugsnag.notify(new Error(`[${identifier} Graphql graphQLErrors]`), e =>
        e.addMetadata("graphQLErrors", graphQLErrors)
      );
      return;
    }

    if (networkError) {
      $bugsnag.notify(new Error(`[${identifier} Graphql networkError]`), e =>
        e.addMetadata("networkError", networkError)
      );
    }
  });

export default errorLink;
