
import { gql } from "graphql-tag";

import { getPrismicTime } from "~/utils/prismic-utils.js";

const CAMPAIGN_BANNER_QUERY = gql`
  query campaign_banner($uid: String!, $lang: String!) {
    campaign_banner(uid: $uid, lang: $lang) {
      text
      timer
      text_color
      background_color
      font_size
    }
  }
`;

const day = 1000 * 60 * 60 * 24;
const hour = 1000 * 60 * 60;
const minute = 1000 * 60;

export default {
  data: () => ({
    bannerText: null,
    campaignBanner: null,
    showBanner: false,
    timer: {},
    dateTimeStr: "",
  }),

  async fetch() {
    const data = await this.$getPrismicData({
      query: CAMPAIGN_BANNER_QUERY,
      variables: { uid: "layout-campaign-banner" },
    });

    if (!data.campaign_banner) {
      return;
    }

    this.campaignBanner = data.campaign_banner;
    this.bannerText = [...(this.campaignBanner?.text ?? [])];
    if (this.campaignBanner?.timer) {
      this.initializeBanner();
    }
  },

  fetchOnServer: false,

  destroyed() {
    clearInterval(this.timer);
    this.showBanner = false;
  },

  methods: {
    initializeBanner() {
      let countDownDate = getPrismicTime(this.campaignBanner.timer);

      this.timer = setInterval(() => {
        this.showBanner = true;
        let timeLeft = countDownDate - Date.now();
        if (timeLeft < 0) {
          clearInterval(this.timer);
          this.showBanner = false;
          return;
        }

        this.setRemainingTimeStr(timeLeft);
      }, 1000);
    },
    setRemainingTimeStr(timeLeft) {
      let days = Math.floor(timeLeft / day);
      let hours = Math.floor((timeLeft % day) / hour);
      let minutes = Math.floor((timeLeft % hour) / minute);
      let seconds = Math.floor((timeLeft % minute) / 1000);

      this.dateTimeStr = `${days} Days  ${hours} Hours ${minutes} Minutes ${seconds} Seconds `;
    },
  },
};
