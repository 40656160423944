import { v4 as uuidv4 } from "uuid";

const uid = uuidv4();
const namespace = uuidv4();

const ONE_YEAR_USER_COOKIE = 60 * 60 * 24 * 365 * 1;

export default ({ app, $config, isDev }, inject) => {
  const searchspring = {
    getIsuid() {
      return app.$cookies.get("_isuid");
    },
    setIsuid() {
      app.$cookies.set("_isuid", uid, {
        path: "/",
        maxAge: ONE_YEAR_USER_COOKIE,
      });
    },
    getSsUserId() {
      return app.$cookies.get("ssUserId");
    },
    setSsUserId() {
      app.$cookies.set("ssUserId", uid, {
        path: "/",
        maxAge: ONE_YEAR_USER_COOKIE,
      });
    },
    getSsSessionIdNamespace() {
      return app.$cookies.get("ssSessionIdNamespace");
    },
    setSsSessionIdNamespace() {
      app.$cookies.set("ssSessionIdNamespace", namespace, {
        path: "/",
      });
    },
    getSsViewedProducts() {
      return app.$cookies.get("ssViewedProducts");
    },
    setSsViewedProducts(sku) {
      const viewedProducts = this.getSsViewedProducts();
      let products = viewedProducts?.includes(sku)
        ? viewedProducts
        : [sku, viewedProducts].filter(Boolean).join(",");

      app.$cookies.set("ssViewedProducts", products, {
        path: "/",
        maxAge: ONE_YEAR_USER_COOKIE * 7,
      });
    },
  };

  inject("searchspring", searchspring);
};
