export const state = () => ({
  showModal: false,
  notificationContent: "",
});

export const actions = {
  openModalAction({ commit }, $config) {
    commit("SET_MODAL_STATE", true);
  },
  closeModalAction({ commit }, $config) {
    commit("SET_MODAL_STATE", false);
  },
  setNotificationContent({ commit }, message) {
    commit("SET_NOTIFICATION_MESSAGE", message);
    setTimeout(() => {
      commit("SET_NOTIFICATION_MESSAGE", "");
    }, 5000);
  },
};

export const mutations = {
  SET_MODAL_STATE(state, val) {
    state.showModal = val;
  },
  SET_NOTIFICATION_MESSAGE(state, message) {
    state.notificationContent = message;
  },
};

export const getters = {
  getModalState(state) {
    return state.showModal;
  },
};
