import { path, pathOr, pick, uniq } from "ramda";

const brandPath = path(["refinementList", "meta.snooze.brand_list"]);
const sizePath = path(["refinementList", "options.size"]);
const colorPath = path(["refinementList", "meta.snooze.color_group"]);
const feelPath = path(["refinementList", "meta.snooze.mattress_comfort_feel"]);
const quickshipPath = path(["refinementList", "meta.snooze.quickship"]);
const titlePath = path(["refinementList", "options.title"]);
const categoryPath = path(["refinementList", "named_tags.category"]);
const pricePath = path(["range", "price"]);
const sortByPath = path(["sortBy"]);

export const facetAttributes = [
  "query",
  "page",
  "brands",
  "sizes",
  "colors",
  "price",
  "sortBy",
  "quickship",
  "title",
  "category",
  "feel",
];

const sortByPathOr = algoliaDefaultSortProductsIndex =>
  pathOr(algoliaDefaultSortProductsIndex, ["sortBy"]);

function getNormalisedValues(values) {
  return Array.isArray(values) ? values : [values].filter(Boolean);
}

export default function getStateMapping(
  indexName,
  facets,
  facetParams,
  algoliaDefaultSortProductsIndex
) {
  return {
    stateToRoute(uiState) {
      const indexUiState = uiState[indexName];

      return {
        query: indexUiState.query,
        page: indexUiState.page,
        brands: brandPath(indexUiState),
        sizes: sizePath(indexUiState),
        colors: colorPath(indexUiState),
        price: pricePath(indexUiState),
        feel: feelPath(indexUiState),
        sortBy: sortByPath(indexUiState),
        quickship: quickshipPath(indexUiState),
        title: titlePath(indexUiState),
        category: categoryPath(indexUiState),
      };
    },
    routeToState(routeState) {
      const sizes = getNormalisedValues(routeState.sizes);
      const brands = getNormalisedValues(routeState.brands);
      const colors = getNormalisedValues(routeState.colors);
      const feel = getNormalisedValues(routeState.feel);
      const quickship = getNormalisedValues(routeState.quickship);
      const title = getNormalisedValues(routeState.title);
      const category = getNormalisedValues(routeState.category);

      // facetParams has the value from paramters
      // e.g. /collection/mattress/products/sizes/Queen
      // facetParams = { sizes: "Queen" }
      const refinementList = {
        "options.size": uniq(sizes.concat(pathOr([], ["sizes"], facetParams))),
        "meta.snooze.color_group": uniq(
          colors.concat(pathOr([], ["colors"], facetParams))
        ),
        "meta.snooze.brand_list": uniq(
          brands.concat(pathOr([], ["brands"], facetParams))
        ),
        "meta.snooze.mattress_comfort_feel": uniq(
          feel.concat(pathOr([], ["feel"], facetParams))
        ),
        "meta.snooze.quickship": uniq(
          quickship.concat(pathOr([], ["quickship"], facetParams))
        ),
        "options.title": uniq(title.concat(pathOr([], ["title"], facetParams))),
        "named_tags.category": uniq(
          category.concat(pathOr([], ["category"], facetParams))
        ),
      };
      const sortBy = sortByPathOr(algoliaDefaultSortProductsIndex)(routeState);

      return {
        [indexName]: pick(facets, {
          query: routeState.query,
          refinementList,
          range: { price: routeState.price },
          sortBy,
          page: routeState.page,
        }),
      };
    },
  };
}
