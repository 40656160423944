import Middleware from "../middleware";

Middleware.wishlist = async function ({ store }) {
  if (process.client) {
    return;
  }

  await store.dispatch("wishlist/fetchCustomer");
  await store.dispatch("wishlist/fetchWishlist");
};
