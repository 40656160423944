/* eslint-disable camelcase */
import { map, evolve, isEmpty, compose, values } from "ramda";

export default {
  methods: {
    getMetaColors(variants) {
      if (isEmpty(variants)) {
        return {};
      }

      const colors = map(
        compose(
          values,
          evolve({
            color_hex: x => x?.value ?? x,
            selectedOptions: x => x?.Colour?.value,
          }),
          ({ selectedOptions, metafields, color_hex, id }) => ({
            selectedOptions,
            color_hex: metafields?.color_hex ?? color_hex,
            id,
          })
        ),
        variants
      );

      return Object.fromEntries(colors.map(([x1, ...x2]) => [x1, x2]));
    },
  },
};
