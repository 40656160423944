export default function ({ route, store, redirect, $config }) {
  const { name, params, query } = route;
  if (!query.sortBy) {
    return;
  }

  const { sortOrders } = store.getters;
  if (!sortOrders.find(item => item.value === query.sortBy)) {
    return redirect({
      name,
      params,
      query: { ...query, sortBy: $config.algoliaDefaultSortProductsIndex },
    });
  }
}
