import https from "https";

export default function ({ $axios, $config }, inject) {
  // Create a custom axios instance
  const agent = new https.Agent({ rejectUnauthorized: false });

  const api = $axios.create({
    httpsAgent: agent,
    https: true,
    progress: false,
    timeout: $config.axiosTimeout,
    retry: { retries: $config.axiosRetryCount },
    headers: {
      common: {
        Accept: "text/plain, */*",
      },
    },
  });

  api.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return {
        ...config,
        baseURL:
          typeof window === "undefined"
            ? $config.localAppEndPoint
            : window?.location?.origin,
      };
      // return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // api.interceptors.request.use(
  //   config => {
  //     const url = config.baseURL
  //       ? `${config.baseURL}/${config.url}`
  //       : config.url;

  //     console.log(
  //       "%c --- Making Request ---",
  //       "color: #4CAF50; font-weight: bold"
  //     );
  //     console.log(`URL : ${url}`);
  //     console.log(`Method : ${config.method}`);
  //     config.data && console.log("Data : ", config.data);

  //     return config;
  //   },

  //   error => {
  //     console.error(
  //       "%c --- Request Error ----",
  //       "color: #EC6060; font-weight: bold"
  //     );
  //     console.error(error);

  //     return Promise.reject(error);
  //   }
  // );

  // api.interceptors.response.use(
  //   response => {
  //     console.log(
  //       "%c --- Response Success ---",
  //       "color: #4CAF50; font-weight: bold"
  //     );

  //     return response;
  //   },

  //   error => {
  //     console.error(
  //       "%c --- Response Error ----",
  //       "color: #EC6060; font-weight: bold"
  //     );

  //     if (error.response) {
  //       // The request was made and the server responded with a status code
  //       // that falls out of the range of 2xx
  //       console.error(error.response.data);
  //       console.error(error.response.status);
  //       console.error(error.response.headers);
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //       // http.ClientRequest in node.js
  //       console.error(error.request);
  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       console.error("Error", error.message);
  //     }
  //     console.error(error.config);

  //     return Promise.reject(error);
  //   }
  // );

  // Set baseURL to something different
  // api.setBaseURL(
  //   process.server ? $config.localAppEndPoint : $config.appEndPoint
  // );

  // Inject to context as $axios
  inject("axios", api);
}
