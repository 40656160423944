import { gql } from "graphql-tag";

const QUERY = gql`
  query collectionByHandle($handle: String!) {
    collection(handle: $handle) {
      id
    }
  }
`;

export default async function ({ app, error, params: { handle } }) {
  const client = app.apolloProvider.defaultClient;
  const { data } = await client.query({ query: QUERY, variables: { handle } });

  if (data.collectionByHandle == null) {
    return error({ statusCode: 404, message: "Page not found" });
  }
}
