export const state = () => ({
  isSearchOpen: false,
  isFilterPanelOpen: false,
  windowWidth: 0,
  isMegaMenuOpen: false,
  activeMenuIndex: -1,
  addedToBagVariants: [],
  isMiniBagOpen: false,
  addedToBagTimeoutId: null,
  isPotraitMode: true,
  isRefinementListVisible: true,
  redirectFrom: {
    pageTitle: "",
    url: "",
  },
  topBannerHeight: 0,
  isGoogleMapLoaded: false,
  recommendedWidgets: [],
  isStoreReviewLoaded: false,
  quickviewProduct: null,
  addingToCartVariants: [],
});

export const actions = {
  openSearch({ commit }, isTablet) {
    commit("setSearch", true);
    if (isTablet) {
      commit("SET_TOGGLE_OVERFLOW_HIDDEN_BODY", true, { root: true });
    }
  },
  closeSearch({ commit }, isTablet) {
    commit("setSearch", false);
    if (isTablet) {
      commit("SET_TOGGLE_OVERFLOW_HIDDEN_BODY", false, { root: true });
    }
  },
  TOGGLE_FILTER_PANEL({ state, commit }) {
    commit("SET_FILTER_PANEL", !state.isFilterPanelOpen);
    commit("SET_TOGGLE_OVERFLOW_HIDDEN_BODY", state.isFilterPanelOpen, {
      root: true,
    });
  },
  CLOSE_FILTER_PANEL({ state, commit }) {
    commit("SET_FILTER_PANEL", false);
    commit("SET_TOGGLE_OVERFLOW_HIDDEN_BODY", false, {
      root: true,
    });
  },
  showMegaMenu({ commit }) {
    commit("TOGGLE_MEGA_MENU", true);
  },
  setActiveMenuIndex({ commit }, index) {
    commit("SET_ACTIVE_MENU_INDEX", index);
  },
  closeMegaMenu({ commit }) {
    commit("TOGGLE_MEGA_MENU", false);
    commit("SET_ACTIVE_MENU_INDEX", -1);
  },
  hydrateRedirectFrom({ commit }) {
    let data = JSON.parse(localStorage?.getItem("redirectFrom") ?? "null");

    if (data?.pageTitle) {
      let payload = {
        title: {
          text: data.pageTitle,
        },
        url: data.url,
      };
      commit("SET_REDIRECT_VALUE", payload);
    }
  },
  async openAddedToBag({ commit, dispatch, rootState }, { items, isTablet }) {
    await dispatch("closeAddedToBag", isTablet);
    commit("SET_ADDED_TO_BAG_VARIANTS", items);
    const timeoutId = setTimeout(
      () => dispatch("closeAddedToBag", isTablet),
      rootState.siteConfig.pdp.addedToBagCloseTime
    );
    commit("SET_ADDED_TO_BAG_TIMEOUT_ID", timeoutId);
    if (isTablet) {
      commit("SET_TOGGLE_OVERFLOW_HIDDEN_BODY", true, { root: true });
    }
  },
  closeAddedToBag({ commit, state }, isTablet) {
    clearTimeout(state.addedToBagTimeoutId);
    commit("SET_ADDED_TO_BAG_TIMEOUT_ID", null);
    commit("SET_ADDED_TO_BAG_VARIANTS", []);
    if (isTablet) {
      commit("SET_TOGGLE_OVERFLOW_HIDDEN_BODY", false, { root: true });
    }
  },
  openMiniBag({ commit }) {
    commit("SET_IS_MINI_BAG_OPEN", true);
  },
  closeMiniBag({ commit }) {
    commit("SET_IS_MINI_BAG_OPEN", false);
  },
  currentOrientation({ commit, state }, orientation) {
    commit("SET_ORIENTATION", orientation.matches);
  },
  setRedirectFrom({ commit }, { title, url }) {
    try {
      localStorage.setItem(
        "redirectFrom",
        JSON.stringify({ pageTitle: title.text, url })
      );
      commit("SET_REDIRECT_VALUE", { title, url });
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },
  setTopBannerHeight({ commit }, height) {
    commit("SET_TOP_BANNER_HEIGHT", height);
  },
  setRefinementListVisiblity({ commit }, isVisible) {
    commit("SET_REFINEMENT_LIST_VISIBILITY", isVisible);
  },
  setGoogleMapLoaded({ commit }) {
    commit("SET_GOOGLE_MAP_LOADED", true);
  },
  setStoreReviewLoaded({ commit }) {
    commit("SET_STORE_REVIEW_LOADED", true);
  },
  setRecommendedWidgets({ commit }, { widgets }) {
    commit("SET_RECOMMENDED_WIDGETS", { widgets });
  },
  openProductQuickview({ commit }, payload) {
    commit("SET_QUICK_VIEW_PRODUCT", payload);
  },
  closeProductQuickview({ commit }) {
    commit("SET_QUICK_VIEW_PRODUCT", null);
  },
  setIsAddingToCart({ commit, state }, { variantId, isAdding }) {
    const index = state.addingToCartVariants.findIndex(
      item => item === variantId
    );

    commit("SET_IS_ADDING_TO_CART", { variantId, index, isAdding });
  },
};

export const mutations = {
  setSearch(state, payload) {
    state.isSearchOpen = payload;
  },
  SET_WINDOW_WIDTH(state, payload) {
    state.windowWidth = payload;
  },
  SET_FILTER_PANEL(state, payload) {
    state.isFilterPanelOpen = payload;
  },
  TOGGLE_MEGA_MENU(state, payload) {
    state.isMegaMenuOpen = payload;
  },
  SET_ACTIVE_MENU_INDEX(state, payload) {
    state.activeMenuIndex = payload;
  },
  SET_ADDED_TO_BAG_VARIANTS(state, payload) {
    state.addedToBagVariants = payload;
  },
  SET_IS_MINI_BAG_OPEN(state, payload) {
    state.isMiniBagOpen = payload;
  },
  SET_ADDED_TO_BAG_TIMEOUT_ID(state, timeoutId) {
    state.addedToBagTimeoutId = timeoutId;
  },
  SET_ORIENTATION(state, orientation) {
    state.isPotraitMode = orientation;
  },
  SET_REDIRECT_VALUE(state, payload) {
    state.redirectFrom.pageTitle = payload.title.text;
    state.redirectFrom.url = payload.url;
  },
  SET_TOP_BANNER_HEIGHT(state, payload) {
    state.topBannerHeight = payload;
  },
  SET_REFINEMENT_LIST_VISIBILITY(state, payload) {
    state.isRefinementListVisible = payload;
  },
  SET_GOOGLE_MAP_LOADED(state, status) {
    state.isGoogleMapLoaded = status;
  },
  SET_STORE_REVIEW_LOADED(state, status) {
    state.isStoreReviewLoaded = status;
  },
  SET_RECOMMENDED_WIDGETS(state, { widgets }) {
    state.recommendedWidgets = [...widgets];
  },
  SET_QUICK_VIEW_PRODUCT(state, payload) {
    state.quickviewProduct = payload;
  },
  SET_IS_ADDING_TO_CART(state, { variantId, isAdding, index }) {
    index = isAdding ? -1 : index;
    state.addingToCartVariants.splice(
      index,
      index === -1 ? 0 : 1,
      index === -1 ? variantId : undefined
    );
  },
};
