
export default {
  head() {
    return {
      script: [
        { src: this.$config.prismicPreviewURL, body: true, defer: true },
      ],
    };
  },
};
