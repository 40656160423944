import { render, staticRenderFns } from "./AppTopBanner.vue?vue&type=template&id=2e4e4b63&scoped=true&"
import script from "./AppTopBanner.vue?vue&type=script&lang=js&"
export * from "./AppTopBanner.vue?vue&type=script&lang=js&"
import style0 from "./AppTopBanner.vue?vue&type=style&index=0&id=2e4e4b63&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e4e4b63",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsRichText: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/cms/CmsRichText.vue').default,SvgIconCross: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/svg/IconCross.vue').default,AppButton: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppButton.vue').default})
