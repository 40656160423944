export const state = () => ({
  velocityPoints: {
    products: [],
    total_base: 0,
    total_bonus: 0,
  },
});

export const actions = {
  async fetchVelocityPoints({ commit }, body) {
    try {
      const url = `${this.$config.snoozeVelocity}/calculate-points`;
      const { data } = await this.$axios.post(url, body, {
        headers: { apikey: this.$config.snoozeSystemApiKey },
      });
      commit("SET_VELOCITY_STATE", data);
    } catch (err) {
      this.$bugsnag.notify(err, e => e.addMetadata("body", body));
    }
  },
};

export const mutations = {
  SET_VELOCITY_STATE(state, points) {
    state.velocityPoints = Object.assign({}, points);
  },
};

export const getters = {
  getVelocityPoints: state => variant => {
    let point = state.velocityPoints.products.find(
      item => item.sku == variant.sku
    );
    return point ? point.base : 0;
  },
  getVelocityBonusPoints: state => variant => {
    let point = state.velocityPoints.products.find(
      item => item.sku == variant.sku
    );
    return point ? point.bonus : 0;
  },
};
