import { gql } from "graphql-tag";

const ImageMediaImage = gql`
  fragment ImageMediaImage on MediaImage {
    image {
      id
      altText
      src: url
      w_1500_h_1125: url(transform: { maxWidth: 1500, maxHeight: 1125 })
      w_1024_h_767: url(
        transform: { maxWidth: 1024, maxHeight: 767, preferredContentType: PNG }
      )
      w_1024_h_767_webp: url(
        transform: {
          maxWidth: 1024
          maxHeight: 767
          preferredContentType: WEBP
        }
      )
      w_60_h_60: url(
        transform: {
          maxWidth: 60
          maxHeight: 60
          preferredContentType: PNG
          crop: TOP
        }
      )
      w_60_h_60_webp: url(
        transform: {
          maxWidth: 60
          maxHeight: 60
          preferredContentType: WEBP
          crop: TOP
        }
      )
    }
  }
`;

const WishlistVariant = gql`
  fragment WishlistVariant on ProductVariant {
    id
    availableForSale
    selectedOptions {
      name
      value
    }
    compareAtPrice {
      amount
    }
    price {
      amount
    }
    images: metafield(namespace: "snooze", key: "images") {
      references(first: 1) {
        nodes {
          ...ImageMediaImage
        }
      }
    }
  }
  ${ImageMediaImage}
`;

const WishlistProductForTiles = gql`
  fragment WishlistProductForTiles on Product {
    variants(first: 100) {
      nodes {
        id
        sku
        title
        compareAtPrice {
          amount
        }
        price {
          amount
        }
        selectedOptions {
          name
          value
        }
        metafields(identifiers: [{ namespace: "snooze", key: "color_hex" }]) {
          value
          key
          type
        }
      }
    }
  }
`;

export { ImageMediaImage, WishlistVariant, WishlistProductForTiles };
