export const SvgCloudBlank = () => import('../../components/svg/CloudBlank.vue' /* webpackChunkName: "components/svg-cloud-blank" */).then(c => wrapFunctional(c.default || c))
export const SvgCloudFill = () => import('../../components/svg/CloudFill.vue' /* webpackChunkName: "components/svg-cloud-fill" */).then(c => wrapFunctional(c.default || c))
export const SvgFilterClearIcon = () => import('../../components/svg/FilterClearIcon.vue' /* webpackChunkName: "components/svg-filter-clear-icon" */).then(c => wrapFunctional(c.default || c))
export const SvgIconAssemblyService = () => import('../../components/svg/IconAssemblyService.vue' /* webpackChunkName: "components/svg-icon-assembly-service" */).then(c => wrapFunctional(c.default || c))
export const SvgIconAustraliaMade = () => import('../../components/svg/IconAustraliaMade.vue' /* webpackChunkName: "components/svg-icon-australia-made" */).then(c => wrapFunctional(c.default || c))
export const SvgIconBackArrow = () => import('../../components/svg/IconBackArrow.vue' /* webpackChunkName: "components/svg-icon-back-arrow" */).then(c => wrapFunctional(c.default || c))
export const SvgIconBirdHumm = () => import('../../components/svg/IconBirdHumm.vue' /* webpackChunkName: "components/svg-icon-bird-humm" */).then(c => wrapFunctional(c.default || c))
export const SvgIconCalendar = () => import('../../components/svg/IconCalendar.vue' /* webpackChunkName: "components/svg-icon-calendar" */).then(c => wrapFunctional(c.default || c))
export const SvgIconCalltoAction = () => import('../../components/svg/IconCalltoAction.vue' /* webpackChunkName: "components/svg-icon-callto-action" */).then(c => wrapFunctional(c.default || c))
export const SvgIconCircle = () => import('../../components/svg/IconCircle.vue' /* webpackChunkName: "components/svg-icon-circle" */).then(c => wrapFunctional(c.default || c))
export const SvgIconClose = () => import('../../components/svg/IconClose.vue' /* webpackChunkName: "components/svg-icon-close" */).then(c => wrapFunctional(c.default || c))
export const SvgIconCross = () => import('../../components/svg/IconCross.vue' /* webpackChunkName: "components/svg-icon-cross" */).then(c => wrapFunctional(c.default || c))
export const SvgIconCustomisable = () => import('../../components/svg/IconCustomisable.vue' /* webpackChunkName: "components/svg-icon-customisable" */).then(c => wrapFunctional(c.default || c))
export const SvgIconCustomizable = () => import('../../components/svg/IconCustomizable.vue' /* webpackChunkName: "components/svg-icon-customizable" */).then(c => wrapFunctional(c.default || c))
export const SvgIconDeliveryBigVan = () => import('../../components/svg/IconDeliveryBigVan.vue' /* webpackChunkName: "components/svg-icon-delivery-big-van" */).then(c => wrapFunctional(c.default || c))
export const SvgIconDeliveryVan = () => import('../../components/svg/IconDeliveryVan.vue' /* webpackChunkName: "components/svg-icon-delivery-van" */).then(c => wrapFunctional(c.default || c))
export const SvgIconDonation = () => import('../../components/svg/IconDonation.vue' /* webpackChunkName: "components/svg-icon-donation" */).then(c => wrapFunctional(c.default || c))
export const SvgIconDownArrow = () => import('../../components/svg/IconDownArrow.vue' /* webpackChunkName: "components/svg-icon-down-arrow" */).then(c => wrapFunctional(c.default || c))
export const SvgIconDownload = () => import('../../components/svg/IconDownload.vue' /* webpackChunkName: "components/svg-icon-download" */).then(c => wrapFunctional(c.default || c))
export const SvgIconElephant = () => import('../../components/svg/IconElephant.vue' /* webpackChunkName: "components/svg-icon-elephant" */).then(c => wrapFunctional(c.default || c))
export const SvgIconEmptyBag = () => import('../../components/svg/IconEmptyBag.vue' /* webpackChunkName: "components/svg-icon-empty-bag" */).then(c => wrapFunctional(c.default || c))
export const SvgIconErrorMessage = () => import('../../components/svg/IconErrorMessage.vue' /* webpackChunkName: "components/svg-icon-error-message" */).then(c => wrapFunctional(c.default || c))
export const SvgIconFillStar = () => import('../../components/svg/IconFillStar.vue' /* webpackChunkName: "components/svg-icon-fill-star" */).then(c => wrapFunctional(c.default || c))
export const SvgIconFilter = () => import('../../components/svg/IconFilter.vue' /* webpackChunkName: "components/svg-icon-filter" */).then(c => wrapFunctional(c.default || c))
export const SvgIconFindStore = () => import('../../components/svg/IconFindStore.vue' /* webpackChunkName: "components/svg-icon-find-store" */).then(c => wrapFunctional(c.default || c))
export const SvgIconGift = () => import('../../components/svg/IconGift.vue' /* webpackChunkName: "components/svg-icon-gift" */).then(c => wrapFunctional(c.default || c))
export const SvgIconGreenTick = () => import('../../components/svg/IconGreenTick.vue' /* webpackChunkName: "components/svg-icon-green-tick" */).then(c => wrapFunctional(c.default || c))
export const SvgIconHamburger = () => import('../../components/svg/IconHamburger.vue' /* webpackChunkName: "components/svg-icon-hamburger" */).then(c => wrapFunctional(c.default || c))
export const SvgIconInStock = () => import('../../components/svg/IconInStock.vue' /* webpackChunkName: "components/svg-icon-in-stock" */).then(c => wrapFunctional(c.default || c))
export const SvgIconInStockAndReady = () => import('../../components/svg/IconInStockAndReady.vue' /* webpackChunkName: "components/svg-icon-in-stock-and-ready" */).then(c => wrapFunctional(c.default || c))
export const SvgIconInfo = () => import('../../components/svg/IconInfo.vue' /* webpackChunkName: "components/svg-icon-info" */).then(c => wrapFunctional(c.default || c))
export const SvgIconInfoLight = () => import('../../components/svg/IconInfoLight.vue' /* webpackChunkName: "components/svg-icon-info-light" */).then(c => wrapFunctional(c.default || c))
export const SvgIconItemSelect = () => import('../../components/svg/IconItemSelect.vue' /* webpackChunkName: "components/svg-icon-item-select" */).then(c => wrapFunctional(c.default || c))
export const SvgIconLocate = () => import('../../components/svg/IconLocate.vue' /* webpackChunkName: "components/svg-icon-locate" */).then(c => wrapFunctional(c.default || c))
export const SvgIconMail = () => import('../../components/svg/IconMail.vue' /* webpackChunkName: "components/svg-icon-mail" */).then(c => wrapFunctional(c.default || c))
export const SvgIconMinus = () => import('../../components/svg/IconMinus.vue' /* webpackChunkName: "components/svg-icon-minus" */).then(c => wrapFunctional(c.default || c))
export const SvgIconMinusCircle = () => import('../../components/svg/IconMinusCircle.vue' /* webpackChunkName: "components/svg-icon-minus-circle" */).then(c => wrapFunctional(c.default || c))
export const SvgIconPlus = () => import('../../components/svg/IconPlus.vue' /* webpackChunkName: "components/svg-icon-plus" */).then(c => wrapFunctional(c.default || c))
export const SvgIconPlusCircle = () => import('../../components/svg/IconPlusCircle.vue' /* webpackChunkName: "components/svg-icon-plus-circle" */).then(c => wrapFunctional(c.default || c))
export const SvgIconProductReview = () => import('../../components/svg/IconProductReview.vue' /* webpackChunkName: "components/svg-icon-product-review" */).then(c => wrapFunctional(c.default || c))
export const SvgIconRemoveBin = () => import('../../components/svg/IconRemoveBin.vue' /* webpackChunkName: "components/svg-icon-remove-bin" */).then(c => wrapFunctional(c.default || c))
export const SvgIconRemoveCircleOutline = () => import('../../components/svg/IconRemoveCircleOutline.vue' /* webpackChunkName: "components/svg-icon-remove-circle-outline" */).then(c => wrapFunctional(c.default || c))
export const SvgIconRightArrow = () => import('../../components/svg/IconRightArrow.vue' /* webpackChunkName: "components/svg-icon-right-arrow" */).then(c => wrapFunctional(c.default || c))
export const SvgIconRightArrowMenu = () => import('../../components/svg/IconRightArrowMenu.vue' /* webpackChunkName: "components/svg-icon-right-arrow-menu" */).then(c => wrapFunctional(c.default || c))
export const SvgIconScale = () => import('../../components/svg/IconScale.vue' /* webpackChunkName: "components/svg-icon-scale" */).then(c => wrapFunctional(c.default || c))
export const SvgIconSearchPlus = () => import('../../components/svg/IconSearchPlus.vue' /* webpackChunkName: "components/svg-icon-search-plus" */).then(c => wrapFunctional(c.default || c))
export const SvgIconSelectBoxArrow = () => import('../../components/svg/IconSelectBoxArrow.vue' /* webpackChunkName: "components/svg-icon-select-box-arrow" */).then(c => wrapFunctional(c.default || c))
export const SvgIconSignup = () => import('../../components/svg/IconSignup.vue' /* webpackChunkName: "components/svg-icon-signup" */).then(c => wrapFunctional(c.default || c))
export const SvgIconSnoozeExclusive = () => import('../../components/svg/IconSnoozeExclusive.vue' /* webpackChunkName: "components/svg-icon-snooze-exclusive" */).then(c => wrapFunctional(c.default || c))
export const SvgIconSnoozeLogo = () => import('../../components/svg/IconSnoozeLogo.vue' /* webpackChunkName: "components/svg-icon-snooze-logo" */).then(c => wrapFunctional(c.default || c))
export const SvgIconStylefitClose = () => import('../../components/svg/IconStylefitClose.vue' /* webpackChunkName: "components/svg-icon-stylefit-close" */).then(c => wrapFunctional(c.default || c))
export const SvgIconThreeSixty = () => import('../../components/svg/IconThreeSixty.vue' /* webpackChunkName: "components/svg-icon-three-sixty" */).then(c => wrapFunctional(c.default || c))
export const SvgIconThreedRotation = () => import('../../components/svg/IconThreedRotation.vue' /* webpackChunkName: "components/svg-icon-threed-rotation" */).then(c => wrapFunctional(c.default || c))
export const SvgIconTickCircle = () => import('../../components/svg/IconTickCircle.vue' /* webpackChunkName: "components/svg-icon-tick-circle" */).then(c => wrapFunctional(c.default || c))
export const SvgIconUnChecked = () => import('../../components/svg/IconUnChecked.vue' /* webpackChunkName: "components/svg-icon-un-checked" */).then(c => wrapFunctional(c.default || c))
export const SvgMemoryStoreIcon = () => import('../../components/svg/MemoryStoreIcon.vue' /* webpackChunkName: "components/svg-memory-store-icon" */).then(c => wrapFunctional(c.default || c))
export const SvgPhoneIcon = () => import('../../components/svg/PhoneIcon.vue' /* webpackChunkName: "components/svg-phone-icon" */).then(c => wrapFunctional(c.default || c))
export const SvgPocketSpringsIcon = () => import('../../components/svg/PocketSpringsIcon.vue' /* webpackChunkName: "components/svg-pocket-springs-icon" */).then(c => wrapFunctional(c.default || c))
export const SvgProfileIcon = () => import('../../components/svg/ProfileIcon.vue' /* webpackChunkName: "components/svg-profile-icon" */).then(c => wrapFunctional(c.default || c))
export const SvgRadioButtonChecked = () => import('../../components/svg/RadioButtonChecked.vue' /* webpackChunkName: "components/svg-radio-button-checked" */).then(c => wrapFunctional(c.default || c))
export const SvgRadioButtonUnchecked = () => import('../../components/svg/RadioButtonUnchecked.vue' /* webpackChunkName: "components/svg-radio-button-unchecked" */).then(c => wrapFunctional(c.default || c))
export const SvgVelocityFrequentFlyer = () => import('../../components/svg/VelocityFrequentFlyer.vue' /* webpackChunkName: "components/svg-velocity-frequent-flyer" */).then(c => wrapFunctional(c.default || c))
export const SvgVelocityLogo = () => import('../../components/svg/VelocityLogo.vue' /* webpackChunkName: "components/svg-velocity-logo" */).then(c => wrapFunctional(c.default || c))
export const IconAmericanExpress = () => import('../../components/dynamic/IconAmericanExpress.vue' /* webpackChunkName: "components/icon-american-express" */).then(c => wrapFunctional(c.default || c))
export const IconAustralianMade = () => import('../../components/dynamic/IconAustralianMade.vue' /* webpackChunkName: "components/icon-australian-made" */).then(c => wrapFunctional(c.default || c))
export const IconCart = () => import('../../components/dynamic/IconCart.vue' /* webpackChunkName: "components/icon-cart" */).then(c => wrapFunctional(c.default || c))
export const IconFacebook = () => import('../../components/dynamic/IconFacebook.vue' /* webpackChunkName: "components/icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconFirm = () => import('../../components/dynamic/IconFirm.vue' /* webpackChunkName: "components/icon-firm" */).then(c => wrapFunctional(c.default || c))
export const IconHouzz = () => import('../../components/dynamic/IconHouzz.vue' /* webpackChunkName: "components/icon-houzz" */).then(c => wrapFunctional(c.default || c))
export const IconHumm = () => import('../../components/dynamic/IconHumm.vue' /* webpackChunkName: "components/icon-humm" */).then(c => wrapFunctional(c.default || c))
export const IconInstagram = () => import('../../components/dynamic/IconInstagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconLocation = () => import('../../components/dynamic/IconLocation.vue' /* webpackChunkName: "components/icon-location" */).then(c => wrapFunctional(c.default || c))
export const IconMastercard = () => import('../../components/dynamic/IconMastercard.vue' /* webpackChunkName: "components/icon-mastercard" */).then(c => wrapFunctional(c.default || c))
export const IconMedium = () => import('../../components/dynamic/IconMedium.vue' /* webpackChunkName: "components/icon-medium" */).then(c => wrapFunctional(c.default || c))
export const IconPaypal = () => import('../../components/dynamic/IconPaypal.vue' /* webpackChunkName: "components/icon-paypal" */).then(c => wrapFunctional(c.default || c))
export const IconPintrest = () => import('../../components/dynamic/IconPintrest.vue' /* webpackChunkName: "components/icon-pintrest" */).then(c => wrapFunctional(c.default || c))
export const IconProfile = () => import('../../components/dynamic/IconProfile.vue' /* webpackChunkName: "components/icon-profile" */).then(c => wrapFunctional(c.default || c))
export const IconSearch = () => import('../../components/dynamic/IconSearch.vue' /* webpackChunkName: "components/icon-search" */).then(c => wrapFunctional(c.default || c))
export const IconSupportSystem = () => import('../../components/dynamic/IconSupportSystem.vue' /* webpackChunkName: "components/icon-support-system" */).then(c => wrapFunctional(c.default || c))
export const IconVisa = () => import('../../components/dynamic/IconVisa.vue' /* webpackChunkName: "components/icon-visa" */).then(c => wrapFunctional(c.default || c))
export const IconWarranty = () => import('../../components/dynamic/IconWarranty.vue' /* webpackChunkName: "components/icon-warranty" */).then(c => wrapFunctional(c.default || c))
export const IconWebsite = () => import('../../components/dynamic/IconWebsite.vue' /* webpackChunkName: "components/icon-website" */).then(c => wrapFunctional(c.default || c))
export const IconWishlist = () => import('../../components/dynamic/IconWishlist.vue' /* webpackChunkName: "components/icon-wishlist" */).then(c => wrapFunctional(c.default || c))
export const IconYoutube = () => import('../../components/dynamic/IconYoutube.vue' /* webpackChunkName: "components/icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const PdpBedsideTables = () => import('../../components/dynamic/PdpBedsideTables.vue' /* webpackChunkName: "components/pdp-bedside-tables" */).then(c => wrapFunctional(c.default || c))
export const PdpBlanketBoxes = () => import('../../components/dynamic/PdpBlanketBoxes.vue' /* webpackChunkName: "components/pdp-blanket-boxes" */).then(c => wrapFunctional(c.default || c))
export const PdpBookcases = () => import('../../components/dynamic/PdpBookcases.vue' /* webpackChunkName: "components/pdp-bookcases" */).then(c => wrapFunctional(c.default || c))
export const PdpCareWarranty = () => import('../../components/dynamic/PdpCareWarranty.vue' /* webpackChunkName: "components/pdp-care-warranty" */).then(c => wrapFunctional(c.default || c))
export const PdpDefaultPlaceholder = () => import('../../components/dynamic/PdpDefaultPlaceholder.vue' /* webpackChunkName: "components/pdp-default-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PdpDeliveryService = () => import('../../components/dynamic/PdpDeliveryService.vue' /* webpackChunkName: "components/pdp-delivery-service" */).then(c => wrapFunctional(c.default || c))
export const PdpDesks = () => import('../../components/dynamic/PdpDesks.vue' /* webpackChunkName: "components/pdp-desks" */).then(c => wrapFunctional(c.default || c))
export const PdpDimension = () => import('../../components/dynamic/PdpDimension.vue' /* webpackChunkName: "components/pdp-dimension" */).then(c => wrapFunctional(c.default || c))
export const PdpDresserWithMirror = () => import('../../components/dynamic/PdpDresserWithMirror.vue' /* webpackChunkName: "components/pdp-dresser-with-mirror" */).then(c => wrapFunctional(c.default || c))
export const PdpDresserWithoutMirror = () => import('../../components/dynamic/PdpDresserWithoutMirror.vue' /* webpackChunkName: "components/pdp-dresser-without-mirror" */).then(c => wrapFunctional(c.default || c))
export const PdpLowboys = () => import('../../components/dynamic/PdpLowboys.vue' /* webpackChunkName: "components/pdp-lowboys" */).then(c => wrapFunctional(c.default || c))
export const PdpMirrors = () => import('../../components/dynamic/PdpMirrors.vue' /* webpackChunkName: "components/pdp-mirrors" */).then(c => wrapFunctional(c.default || c))
export const PdpTallboys = () => import('../../components/dynamic/PdpTallboys.vue' /* webpackChunkName: "components/pdp-tallboys" */).then(c => wrapFunctional(c.default || c))
export const PdpTrueBeds = () => import('../../components/dynamic/PdpTrueBeds.vue' /* webpackChunkName: "components/pdp-true-beds" */).then(c => wrapFunctional(c.default || c))
export const PdpTrueMattressesOnly = () => import('../../components/dynamic/PdpTrueMattressesOnly.vue' /* webpackChunkName: "components/pdp-true-mattresses-only" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaLoadingIndicator = () => import('../../components/AlgoliaLoadingIndicator.vue' /* webpackChunkName: "components/algolia-loading-indicator" */).then(c => wrapFunctional(c.default || c))
export const CollectionReview = () => import('../../components/CollectionReview.vue' /* webpackChunkName: "components/collection-review" */).then(c => wrapFunctional(c.default || c))
export const LazyImage = () => import('../../components/LazyImage.vue' /* webpackChunkName: "components/lazy-image" */).then(c => wrapFunctional(c.default || c))
export const LoadingIcon = () => import('../../components/LoadingIcon.vue' /* webpackChunkName: "components/loading-icon" */).then(c => wrapFunctional(c.default || c))
export const PaymentIcons = () => import('../../components/PaymentIcons.vue' /* webpackChunkName: "components/payment-icons" */).then(c => wrapFunctional(c.default || c))
export const RatingStars = () => import('../../components/RatingStars.vue' /* webpackChunkName: "components/rating-stars" */).then(c => wrapFunctional(c.default || c))
export const ShopifyImage = () => import('../../components/ShopifyImage.vue' /* webpackChunkName: "components/shopify-image" */).then(c => wrapFunctional(c.default || c))
export const StaticPageBreadCrumb = () => import('../../components/StaticPageBreadCrumb.vue' /* webpackChunkName: "components/static-page-bread-crumb" */).then(c => wrapFunctional(c.default || c))
export const StaticPageNav = () => import('../../components/StaticPageNav.vue' /* webpackChunkName: "components/static-page-nav" */).then(c => wrapFunctional(c.default || c))
export const BagAutoClub = () => import('../../components/bag/BagAutoClub.vue' /* webpackChunkName: "components/bag-auto-club" */).then(c => wrapFunctional(c.default || c))
export const BagAutoClubBody = () => import('../../components/bag/BagAutoClubBody.vue' /* webpackChunkName: "components/bag-auto-club-body" */).then(c => wrapFunctional(c.default || c))
export const BagAutoClubForm = () => import('../../components/bag/BagAutoClubForm.vue' /* webpackChunkName: "components/bag-auto-club-form" */).then(c => wrapFunctional(c.default || c))
export const BagAutoClubTitle = () => import('../../components/bag/BagAutoClubTitle.vue' /* webpackChunkName: "components/bag-auto-club-title" */).then(c => wrapFunctional(c.default || c))
export const BagBenefitsContainer = () => import('../../components/bag/BagBenefitsContainer.vue' /* webpackChunkName: "components/bag-benefits-container" */).then(c => wrapFunctional(c.default || c))
export const BagCheckoutButton = () => import('../../components/bag/BagCheckoutButton.vue' /* webpackChunkName: "components/bag-checkout-button" */).then(c => wrapFunctional(c.default || c))
export const BagCheckoutSavings = () => import('../../components/bag/BagCheckoutSavings.vue' /* webpackChunkName: "components/bag-checkout-savings" */).then(c => wrapFunctional(c.default || c))
export const BagDeliveryEstimator = () => import('../../components/bag/BagDeliveryEstimator.vue' /* webpackChunkName: "components/bag-delivery-estimator" */).then(c => wrapFunctional(c.default || c))
export const BagDonation = () => import('../../components/bag/BagDonation.vue' /* webpackChunkName: "components/bag-donation" */).then(c => wrapFunctional(c.default || c))
export const BagEmptyState = () => import('../../components/bag/BagEmptyState.vue' /* webpackChunkName: "components/bag-empty-state" */).then(c => wrapFunctional(c.default || c))
export const BagGroupHeader = () => import('../../components/bag/BagGroupHeader.vue' /* webpackChunkName: "components/bag-group-header" */).then(c => wrapFunctional(c.default || c))
export const BagItemAssemblyPicker = () => import('../../components/bag/BagItemAssemblyPicker.vue' /* webpackChunkName: "components/bag-item-assembly-picker" */).then(c => wrapFunctional(c.default || c))
export const BagItemBonus = () => import('../../components/bag/BagItemBonus.vue' /* webpackChunkName: "components/bag-item-bonus" */).then(c => wrapFunctional(c.default || c))
export const BagItemCampaign = () => import('../../components/bag/BagItemCampaign.vue' /* webpackChunkName: "components/bag-item-campaign" */).then(c => wrapFunctional(c.default || c))
export const BagItemImage = () => import('../../components/bag/BagItemImage.vue' /* webpackChunkName: "components/bag-item-image" */).then(c => wrapFunctional(c.default || c))
export const BagItemLeadTime = () => import('../../components/bag/BagItemLeadTime.vue' /* webpackChunkName: "components/bag-item-lead-time" */).then(c => wrapFunctional(c.default || c))
export const BagItemMessages = () => import('../../components/bag/BagItemMessages.vue' /* webpackChunkName: "components/bag-item-messages" */).then(c => wrapFunctional(c.default || c))
export const BagItemOffer = () => import('../../components/bag/BagItemOffer.vue' /* webpackChunkName: "components/bag-item-offer" */).then(c => wrapFunctional(c.default || c))
export const BagItemOptions = () => import('../../components/bag/BagItemOptions.vue' /* webpackChunkName: "components/bag-item-options" */).then(c => wrapFunctional(c.default || c))
export const BagItemRemoveModal = () => import('../../components/bag/BagItemRemoveModal.vue' /* webpackChunkName: "components/bag-item-remove-modal" */).then(c => wrapFunctional(c.default || c))
export const BagItemRow = () => import('../../components/bag/BagItemRow.vue' /* webpackChunkName: "components/bag-item-row" */).then(c => wrapFunctional(c.default || c))
export const BagItemStepper = () => import('../../components/bag/BagItemStepper.vue' /* webpackChunkName: "components/bag-item-stepper" */).then(c => wrapFunctional(c.default || c))
export const BagItemTitle = () => import('../../components/bag/BagItemTitle.vue' /* webpackChunkName: "components/bag-item-title" */).then(c => wrapFunctional(c.default || c))
export const BagItemsContainer = () => import('../../components/bag/BagItemsContainer.vue' /* webpackChunkName: "components/bag-items-container" */).then(c => wrapFunctional(c.default || c))
export const BagNonEmptyState = () => import('../../components/bag/BagNonEmptyState.vue' /* webpackChunkName: "components/bag-non-empty-state" */).then(c => wrapFunctional(c.default || c))
export const BagPriceSummary = () => import('../../components/bag/BagPriceSummary.vue' /* webpackChunkName: "components/bag-price-summary" */).then(c => wrapFunctional(c.default || c))
export const BagSharingCartMessage = () => import('../../components/bag/BagSharingCartMessage.vue' /* webpackChunkName: "components/bag-sharing-cart-message" */).then(c => wrapFunctional(c.default || c))
export const BagSharingModal = () => import('../../components/bag/BagSharingModal.vue' /* webpackChunkName: "components/bag-sharing-modal" */).then(c => wrapFunctional(c.default || c))
export const BagShippingBanner = () => import('../../components/bag/BagShippingBanner.vue' /* webpackChunkName: "components/bag-shipping-banner" */).then(c => wrapFunctional(c.default || c))
export const BagSnoozeMember = () => import('../../components/bag/BagSnoozeMember.vue' /* webpackChunkName: "components/bag-snooze-member" */).then(c => wrapFunctional(c.default || c))
export const BagSnoozeMemberBody = () => import('../../components/bag/BagSnoozeMemberBody.vue' /* webpackChunkName: "components/bag-snooze-member-body" */).then(c => wrapFunctional(c.default || c))
export const BagSnoozeMemberForm = () => import('../../components/bag/BagSnoozeMemberForm.vue' /* webpackChunkName: "components/bag-snooze-member-form" */).then(c => wrapFunctional(c.default || c))
export const BagSnoozeMemberTitle = () => import('../../components/bag/BagSnoozeMemberTitle.vue' /* webpackChunkName: "components/bag-snooze-member-title" */).then(c => wrapFunctional(c.default || c))
export const BagStickyFooter = () => import('../../components/bag/BagStickyFooter.vue' /* webpackChunkName: "components/bag-sticky-footer" */).then(c => wrapFunctional(c.default || c))
export const BagVelocity = () => import('../../components/bag/BagVelocity.vue' /* webpackChunkName: "components/bag-velocity" */).then(c => wrapFunctional(c.default || c))
export const BagVelocityBody = () => import('../../components/bag/BagVelocityBody.vue' /* webpackChunkName: "components/bag-velocity-body" */).then(c => wrapFunctional(c.default || c))
export const BagVelocityForm = () => import('../../components/bag/BagVelocityForm.vue' /* webpackChunkName: "components/bag-velocity-form" */).then(c => wrapFunctional(c.default || c))
export const BagVelocityPoints = () => import('../../components/bag/BagVelocityPoints.vue' /* webpackChunkName: "components/bag-velocity-points" */).then(c => wrapFunctional(c.default || c))
export const BagVelocityTitle = () => import('../../components/bag/BagVelocityTitle.vue' /* webpackChunkName: "components/bag-velocity-title" */).then(c => wrapFunctional(c.default || c))
export const BagWarningRefreshModal = () => import('../../components/bag/BagWarningRefreshModal.vue' /* webpackChunkName: "components/bag-warning-refresh-modal" */).then(c => wrapFunctional(c.default || c))
export const BlogCategories = () => import('../../components/blog/BlogCategories.vue' /* webpackChunkName: "components/blog-categories" */).then(c => wrapFunctional(c.default || c))
export const BlogDetailPagination = () => import('../../components/blog/BlogDetailPagination.vue' /* webpackChunkName: "components/blog-detail-pagination" */).then(c => wrapFunctional(c.default || c))
export const BlogItem = () => import('../../components/blog/BlogItem.vue' /* webpackChunkName: "components/blog-item" */).then(c => wrapFunctional(c.default || c))
export const BlogItemsContainer = () => import('../../components/blog/BlogItemsContainer.vue' /* webpackChunkName: "components/blog-items-container" */).then(c => wrapFunctional(c.default || c))
export const BlogListPageTitle = () => import('../../components/blog/BlogListPageTitle.vue' /* webpackChunkName: "components/blog-list-page-title" */).then(c => wrapFunctional(c.default || c))
export const BlogPagination = () => import('../../components/blog/BlogPagination.vue' /* webpackChunkName: "components/blog-pagination" */).then(c => wrapFunctional(c.default || c))
export const BlogRecent = () => import('../../components/blog/BlogRecent.vue' /* webpackChunkName: "components/blog-recent" */).then(c => wrapFunctional(c.default || c))
export const CmsEmbed = () => import('../../components/cms/CmsEmbed.vue' /* webpackChunkName: "components/cms-embed" */).then(c => wrapFunctional(c.default || c))
export const CmsImage = () => import('../../components/cms/CmsImage.vue' /* webpackChunkName: "components/cms-image" */).then(c => wrapFunctional(c.default || c))
export const CmsLink = () => import('../../components/cms/CmsLink.vue' /* webpackChunkName: "components/cms-link" */).then(c => wrapFunctional(c.default || c))
export const CmsRichText = () => import('../../components/cms/CmsRichText.vue' /* webpackChunkName: "components/cms-rich-text" */).then(c => wrapFunctional(c.default || c))
export const CmsSliceZone = () => import('../../components/cms/CmsSliceZone.vue' /* webpackChunkName: "components/cms-slice-zone" */).then(c => wrapFunctional(c.default || c))
export const AppAddToCart = () => import('../../components/common/AppAddToCart.vue' /* webpackChunkName: "components/app-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AppBackToTop = () => import('../../components/common/AppBackToTop.vue' /* webpackChunkName: "components/app-back-to-top" */).then(c => wrapFunctional(c.default || c))
export const AppBackToTopButton = () => import('../../components/common/AppBackToTopButton.vue' /* webpackChunkName: "components/app-back-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const AppBadge = () => import('../../components/common/AppBadge.vue' /* webpackChunkName: "components/app-badge" */).then(c => wrapFunctional(c.default || c))
export const AppBreadCrumb = () => import('../../components/common/AppBreadCrumb.vue' /* webpackChunkName: "components/app-bread-crumb" */).then(c => wrapFunctional(c.default || c))
export const AppButton = () => import('../../components/common/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c))
export const AppCampaignBanner = () => import('../../components/common/AppCampaignBanner.vue' /* webpackChunkName: "components/app-campaign-banner" */).then(c => wrapFunctional(c.default || c))
export const AppCampaignIcons = () => import('../../components/common/AppCampaignIcons.vue' /* webpackChunkName: "components/app-campaign-icons" */).then(c => wrapFunctional(c.default || c))
export const AppCheckbox = () => import('../../components/common/AppCheckbox.vue' /* webpackChunkName: "components/app-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AppContainer = () => import('../../components/common/AppContainer.vue' /* webpackChunkName: "components/app-container" */).then(c => wrapFunctional(c.default || c))
export const AppExpansionPanel = () => import('../../components/common/AppExpansionPanel.vue' /* webpackChunkName: "components/app-expansion-panel" */).then(c => wrapFunctional(c.default || c))
export const AppExpansionPanels = () => import('../../components/common/AppExpansionPanels.vue' /* webpackChunkName: "components/app-expansion-panels" */).then(c => wrapFunctional(c.default || c))
export const AppExternalLink = () => import('../../components/common/AppExternalLink.vue' /* webpackChunkName: "components/app-external-link" */).then(c => wrapFunctional(c.default || c))
export const AppImg = () => import('../../components/common/AppImg.vue' /* webpackChunkName: "components/app-img" */).then(c => wrapFunctional(c.default || c))
export const AppLiteVideo = () => import('../../components/common/AppLiteVideo.vue' /* webpackChunkName: "components/app-lite-video" */).then(c => wrapFunctional(c.default || c))
export const AppPlaceAutocomplete = () => import('../../components/common/AppPlaceAutocomplete.vue' /* webpackChunkName: "components/app-place-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const AppProductPrice = () => import('../../components/common/AppProductPrice.vue' /* webpackChunkName: "components/app-product-price" */).then(c => wrapFunctional(c.default || c))
export const AppProductTilePrice = () => import('../../components/common/AppProductTilePrice.vue' /* webpackChunkName: "components/app-product-tile-price" */).then(c => wrapFunctional(c.default || c))
export const AppRadiobox = () => import('../../components/common/AppRadiobox.vue' /* webpackChunkName: "components/app-radiobox" */).then(c => wrapFunctional(c.default || c))
export const AppSaleBody = () => import('../../components/common/AppSaleBody.vue' /* webpackChunkName: "components/app-sale-body" */).then(c => wrapFunctional(c.default || c))
export const AppSelectBox = () => import('../../components/common/AppSelectBox.vue' /* webpackChunkName: "components/app-select-box" */).then(c => wrapFunctional(c.default || c))
export const AppSelectBoxItem = () => import('../../components/common/AppSelectBoxItem.vue' /* webpackChunkName: "components/app-select-box-item" */).then(c => wrapFunctional(c.default || c))
export const AppShopButton = () => import('../../components/common/AppShopButton.vue' /* webpackChunkName: "components/app-shop-button" */).then(c => wrapFunctional(c.default || c))
export const AppShopifyProductTile = () => import('../../components/common/AppShopifyProductTile.vue' /* webpackChunkName: "components/app-shopify-product-tile" */).then(c => wrapFunctional(c.default || c))
export const AppSliceLoader = () => import('../../components/common/AppSliceLoader.vue' /* webpackChunkName: "components/app-slice-loader" */).then(c => wrapFunctional(c.default || c))
export const AppToggleSwitch = () => import('../../components/common/AppToggleSwitch.vue' /* webpackChunkName: "components/app-toggle-switch" */).then(c => wrapFunctional(c.default || c))
export const AppTopBanner = () => import('../../components/common/AppTopBanner.vue' /* webpackChunkName: "components/app-top-banner" */).then(c => wrapFunctional(c.default || c))
export const ContactUsServiceContent = () => import('../../components/contact-us/ContactUsServiceContent.vue' /* webpackChunkName: "components/contact-us-service-content" */).then(c => wrapFunctional(c.default || c))
export const DesktopMenuItem = () => import('../../components/layout/DesktopMenuItem.vue' /* webpackChunkName: "components/desktop-menu-item" */).then(c => wrapFunctional(c.default || c))
export const FirstLevelMenu = () => import('../../components/layout/FirstLevelMenu.vue' /* webpackChunkName: "components/first-level-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderRightIcons = () => import('../../components/layout/HeaderRightIcons.vue' /* webpackChunkName: "components/header-right-icons" */).then(c => wrapFunctional(c.default || c))
export const MainCampaignBanner = () => import('../../components/layout/MainCampaignBanner.vue' /* webpackChunkName: "components/main-campaign-banner" */).then(c => wrapFunctional(c.default || c))
export const SiteFooter = () => import('../../components/layout/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c))
export const SiteFooterLinks = () => import('../../components/layout/SiteFooterLinks.vue' /* webpackChunkName: "components/site-footer-links" */).then(c => wrapFunctional(c.default || c))
export const SiteHeader = () => import('../../components/layout/SiteHeader.vue' /* webpackChunkName: "components/site-header" */).then(c => wrapFunctional(c.default || c))
export const TabletMenuItem = () => import('../../components/layout/TabletMenuItem.vue' /* webpackChunkName: "components/tablet-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MegaMenu = () => import('../../components/mega-menu/MegaMenu.vue' /* webpackChunkName: "components/mega-menu" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuBanner = () => import('../../components/mega-menu/MegaMenuBanner.vue' /* webpackChunkName: "components/mega-menu-banner" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuColumn = () => import('../../components/mega-menu/MegaMenuColumn.vue' /* webpackChunkName: "components/mega-menu-column" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuColumns = () => import('../../components/mega-menu/MegaMenuColumns.vue' /* webpackChunkName: "components/mega-menu-columns" */).then(c => wrapFunctional(c.default || c))
export const MegaMenuItem = () => import('../../components/mega-menu/MegaMenuItem.vue' /* webpackChunkName: "components/mega-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MiniBagEmpty = () => import('../../components/mini-bag-modal/MiniBagEmpty.vue' /* webpackChunkName: "components/mini-bag-empty" */).then(c => wrapFunctional(c.default || c))
export const MiniBagItem = () => import('../../components/mini-bag-modal/MiniBagItem.vue' /* webpackChunkName: "components/mini-bag-item" */).then(c => wrapFunctional(c.default || c))
export const MiniBagModal = () => import('../../components/mini-bag-modal/MiniBagModal.vue' /* webpackChunkName: "components/mini-bag-modal" */).then(c => wrapFunctional(c.default || c))
export const MiniBagPrice = () => import('../../components/mini-bag-modal/MiniBagPrice.vue' /* webpackChunkName: "components/mini-bag-price" */).then(c => wrapFunctional(c.default || c))
export const PdpAddToWishlist = () => import('../../components/pdp/PdpAddToWishlist.vue' /* webpackChunkName: "components/pdp-add-to-wishlist" */).then(c => wrapFunctional(c.default || c))
export const PdpAddedLineItem = () => import('../../components/pdp/PdpAddedLineItem.vue' /* webpackChunkName: "components/pdp-added-line-item" */).then(c => wrapFunctional(c.default || c))
export const PdpAddedToBagModal = () => import('../../components/pdp/PdpAddedToBagModal.vue' /* webpackChunkName: "components/pdp-added-to-bag-modal" */).then(c => wrapFunctional(c.default || c))
export const PdpAugRealityModal = () => import('../../components/pdp/PdpAugRealityModal.vue' /* webpackChunkName: "components/pdp-aug-reality-modal" */).then(c => wrapFunctional(c.default || c))
export const PdpBonusOffer = () => import('../../components/pdp/PdpBonusOffer.vue' /* webpackChunkName: "components/pdp-bonus-offer" */).then(c => wrapFunctional(c.default || c))
export const PdpComfortFeel = () => import('../../components/pdp/PdpComfortFeel.vue' /* webpackChunkName: "components/pdp-comfort-feel" */).then(c => wrapFunctional(c.default || c))
export const PdpComfortScale = () => import('../../components/pdp/PdpComfortScale.vue' /* webpackChunkName: "components/pdp-comfort-scale" */).then(c => wrapFunctional(c.default || c))
export const PdpDeliveryOptions = () => import('../../components/pdp/PdpDeliveryOptions.vue' /* webpackChunkName: "components/pdp-delivery-options" */).then(c => wrapFunctional(c.default || c))
export const PdpDeliveryTab = () => import('../../components/pdp/PdpDeliveryTab.vue' /* webpackChunkName: "components/pdp-delivery-tab" */).then(c => wrapFunctional(c.default || c))
export const PdpDescription = () => import('../../components/pdp/PdpDescription.vue' /* webpackChunkName: "components/pdp-description" */).then(c => wrapFunctional(c.default || c))
export const PdpFeatureAccordion = () => import('../../components/pdp/PdpFeatureAccordion.vue' /* webpackChunkName: "components/pdp-feature-accordion" */).then(c => wrapFunctional(c.default || c))
export const PdpFeatureTile = () => import('../../components/pdp/PdpFeatureTile.vue' /* webpackChunkName: "components/pdp-feature-tile" */).then(c => wrapFunctional(c.default || c))
export const PdpFeatures = () => import('../../components/pdp/PdpFeatures.vue' /* webpackChunkName: "components/pdp-features" */).then(c => wrapFunctional(c.default || c))
export const PdpFindStore = () => import('../../components/pdp/PdpFindStore.vue' /* webpackChunkName: "components/pdp-find-store" */).then(c => wrapFunctional(c.default || c))
export const PdpImage = () => import('../../components/pdp/PdpImage.vue' /* webpackChunkName: "components/pdp-image" */).then(c => wrapFunctional(c.default || c))
export const PdpImageModal = () => import('../../components/pdp/PdpImageModal.vue' /* webpackChunkName: "components/pdp-image-modal" */).then(c => wrapFunctional(c.default || c))
export const PdpImageSwiper = () => import('../../components/pdp/PdpImageSwiper.vue' /* webpackChunkName: "components/pdp-image-swiper" */).then(c => wrapFunctional(c.default || c))
export const PdpImageZoomer = () => import('../../components/pdp/PdpImageZoomer.vue' /* webpackChunkName: "components/pdp-image-zoomer" */).then(c => wrapFunctional(c.default || c))
export const PdpInfoModal = () => import('../../components/pdp/PdpInfoModal.vue' /* webpackChunkName: "components/pdp-info-modal" */).then(c => wrapFunctional(c.default || c))
export const PdpMainInfo = () => import('../../components/pdp/PdpMainInfo.vue' /* webpackChunkName: "components/pdp-main-info" */).then(c => wrapFunctional(c.default || c))
export const PdpMattressProtector = () => import('../../components/pdp/PdpMattressProtector.vue' /* webpackChunkName: "components/pdp-mattress-protector" */).then(c => wrapFunctional(c.default || c))
export const PdpProductSchema = () => import('../../components/pdp/PdpProductSchema.vue' /* webpackChunkName: "components/pdp-product-schema" */).then(c => wrapFunctional(c.default || c))
export const PdpRecommendedProduct = () => import('../../components/pdp/PdpRecommendedProduct.vue' /* webpackChunkName: "components/pdp-recommended-product" */).then(c => wrapFunctional(c.default || c))
export const PdpReviewContainer = () => import('../../components/pdp/PdpReviewContainer.vue' /* webpackChunkName: "components/pdp-review-container" */).then(c => wrapFunctional(c.default || c))
export const PdpReviewCustomRating = () => import('../../components/pdp/PdpReviewCustomRating.vue' /* webpackChunkName: "components/pdp-review-custom-rating" */).then(c => wrapFunctional(c.default || c))
export const PdpReviewListItem = () => import('../../components/pdp/PdpReviewListItem.vue' /* webpackChunkName: "components/pdp-review-list-item" */).then(c => wrapFunctional(c.default || c))
export const PdpReviewPoints = () => import('../../components/pdp/PdpReviewPoints.vue' /* webpackChunkName: "components/pdp-review-points" */).then(c => wrapFunctional(c.default || c))
export const PdpStickyHeader = () => import('../../components/pdp/PdpStickyHeader.vue' /* webpackChunkName: "components/pdp-sticky-header" */).then(c => wrapFunctional(c.default || c))
export const PdpStylefit = () => import('../../components/pdp/PdpStylefit.vue' /* webpackChunkName: "components/pdp-stylefit" */).then(c => wrapFunctional(c.default || c))
export const PdpTiles = () => import('../../components/pdp/PdpTiles.vue' /* webpackChunkName: "components/pdp-tiles" */).then(c => wrapFunctional(c.default || c))
export const PdpTilesForBase = () => import('../../components/pdp/PdpTilesForBase.vue' /* webpackChunkName: "components/pdp-tiles-for-base" */).then(c => wrapFunctional(c.default || c))
export const PdpTilesForColor = () => import('../../components/pdp/PdpTilesForColor.vue' /* webpackChunkName: "components/pdp-tiles-for-color" */).then(c => wrapFunctional(c.default || c))
export const PdpTilesForFeel = () => import('../../components/pdp/PdpTilesForFeel.vue' /* webpackChunkName: "components/pdp-tiles-for-feel" */).then(c => wrapFunctional(c.default || c))
export const PdpTilesForSize = () => import('../../components/pdp/PdpTilesForSize.vue' /* webpackChunkName: "components/pdp-tiles-for-size" */).then(c => wrapFunctional(c.default || c))
export const PdpVelocityPoints = () => import('../../components/pdp/PdpVelocityPoints.vue' /* webpackChunkName: "components/pdp-velocity-points" */).then(c => wrapFunctional(c.default || c))
export const PdpVideo = () => import('../../components/pdp/PdpVideo.vue' /* webpackChunkName: "components/pdp-video" */).then(c => wrapFunctional(c.default || c))
export const PdpYotpoRating = () => import('../../components/pdp/PdpYotpoRating.vue' /* webpackChunkName: "components/pdp-yotpo-rating" */).then(c => wrapFunctional(c.default || c))
export const PlpAllRefinements = () => import('../../components/plp/PlpAllRefinements.vue' /* webpackChunkName: "components/plp-all-refinements" */).then(c => wrapFunctional(c.default || c))
export const PlpAllSelectedRefinements = () => import('../../components/plp/PlpAllSelectedRefinements.vue' /* webpackChunkName: "components/plp-all-selected-refinements" */).then(c => wrapFunctional(c.default || c))
export const PlpChip = () => import('../../components/plp/PlpChip.vue' /* webpackChunkName: "components/plp-chip" */).then(c => wrapFunctional(c.default || c))
export const PlpClearAllRefinements = () => import('../../components/plp/PlpClearAllRefinements.vue' /* webpackChunkName: "components/plp-clear-all-refinements" */).then(c => wrapFunctional(c.default || c))
export const PlpListRefinement = () => import('../../components/plp/PlpListRefinement.vue' /* webpackChunkName: "components/plp-list-refinement" */).then(c => wrapFunctional(c.default || c))
export const PlpPriceRangeRefinement = () => import('../../components/plp/PlpPriceRangeRefinement.vue' /* webpackChunkName: "components/plp-price-range-refinement" */).then(c => wrapFunctional(c.default || c))
export const PlpProductHits = () => import('../../components/plp/PlpProductHits.vue' /* webpackChunkName: "components/plp-product-hits" */).then(c => wrapFunctional(c.default || c))
export const ProductFilterPanel = () => import('../../components/plp/ProductFilterPanel.vue' /* webpackChunkName: "components/product-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const ProductMain = () => import('../../components/plp/ProductMain.vue' /* webpackChunkName: "components/product-main" */).then(c => wrapFunctional(c.default || c))
export const ProductSorting = () => import('../../components/plp/ProductSorting.vue' /* webpackChunkName: "components/product-sorting" */).then(c => wrapFunctional(c.default || c))
export const ProductTagConfigure = () => import('../../components/plp/ProductTagConfigure.vue' /* webpackChunkName: "components/product-tag-configure" */).then(c => wrapFunctional(c.default || c))
export const ProductQuickviewImageSwiper = () => import('../../components/product-quickview/ProductQuickviewImageSwiper.vue' /* webpackChunkName: "components/product-quickview-image-swiper" */).then(c => wrapFunctional(c.default || c))
export const ProductQuickviewMain = () => import('../../components/product-quickview/ProductQuickviewMain.vue' /* webpackChunkName: "components/product-quickview-main" */).then(c => wrapFunctional(c.default || c))
export const ProductQuickviewModal = () => import('../../components/product-quickview/ProductQuickviewModal.vue' /* webpackChunkName: "components/product-quickview-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductTile = () => import('../../components/product-tile/ProductTile.vue' /* webpackChunkName: "components/product-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductTileBrand = () => import('../../components/product-tile/ProductTileBrand.vue' /* webpackChunkName: "components/product-tile-brand" */).then(c => wrapFunctional(c.default || c))
export const ProductTileColors = () => import('../../components/product-tile/ProductTileColors.vue' /* webpackChunkName: "components/product-tile-colors" */).then(c => wrapFunctional(c.default || c))
export const ProductTileIconHeart = () => import('../../components/product-tile/ProductTileIconHeart.vue' /* webpackChunkName: "components/product-tile-icon-heart" */).then(c => wrapFunctional(c.default || c))
export const ProductTileImageBanner = () => import('../../components/product-tile/ProductTileImageBanner.vue' /* webpackChunkName: "components/product-tile-image-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductTileImages = () => import('../../components/product-tile/ProductTileImages.vue' /* webpackChunkName: "components/product-tile-images" */).then(c => wrapFunctional(c.default || c))
export const ProductTileRating = () => import('../../components/product-tile/ProductTileRating.vue' /* webpackChunkName: "components/product-tile-rating" */).then(c => wrapFunctional(c.default || c))
export const ProductTileSizes = () => import('../../components/product-tile/ProductTileSizes.vue' /* webpackChunkName: "components/product-tile-sizes" */).then(c => wrapFunctional(c.default || c))
export const ProductTileTitle = () => import('../../components/product-tile/ProductTileTitle.vue' /* webpackChunkName: "components/product-tile-title" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/search/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchBlogList = () => import('../../components/search/SearchBlogList.vue' /* webpackChunkName: "components/search-blog-list" */).then(c => wrapFunctional(c.default || c))
export const SearchClearAll = () => import('../../components/search/SearchClearAll.vue' /* webpackChunkName: "components/search-clear-all" */).then(c => wrapFunctional(c.default || c))
export const SearchCollectionList = () => import('../../components/search/SearchCollectionList.vue' /* webpackChunkName: "components/search-collection-list" */).then(c => wrapFunctional(c.default || c))
export const SearchProduct = () => import('../../components/search/SearchProduct.vue' /* webpackChunkName: "components/search-product" */).then(c => wrapFunctional(c.default || c))
export const SearchProductList = () => import('../../components/search/SearchProductList.vue' /* webpackChunkName: "components/search-product-list" */).then(c => wrapFunctional(c.default || c))
export const SearchQueryBox = () => import('../../components/search/SearchQueryBox.vue' /* webpackChunkName: "components/search-query-box" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/search/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const StoreDetailsMap = () => import('../../components/store/StoreDetailsMap.vue' /* webpackChunkName: "components/store-details-map" */).then(c => wrapFunctional(c.default || c))
export const StoreFeedbackForm = () => import('../../components/store/StoreFeedbackForm.vue' /* webpackChunkName: "components/store-feedback-form" */).then(c => wrapFunctional(c.default || c))
export const StoreItem = () => import('../../components/store/StoreItem.vue' /* webpackChunkName: "components/store-item" */).then(c => wrapFunctional(c.default || c))
export const StoreMap = () => import('../../components/store/StoreMap.vue' /* webpackChunkName: "components/store-map" */).then(c => wrapFunctional(c.default || c))
export const StoreOpenDetails = () => import('../../components/store/StoreOpenDetails.vue' /* webpackChunkName: "components/store-open-details" */).then(c => wrapFunctional(c.default || c))
export const SwiperNav = () => import('../../components/swiper-nav/SwiperNav.vue' /* webpackChunkName: "components/swiper-nav" */).then(c => wrapFunctional(c.default || c))
export const WishlistAddAllToCart = () => import('../../components/wishlist/WishlistAddAllToCart.vue' /* webpackChunkName: "components/wishlist-add-all-to-cart" */).then(c => wrapFunctional(c.default || c))
export const WishlistAddProduct = () => import('../../components/wishlist/WishlistAddProduct.vue' /* webpackChunkName: "components/wishlist-add-product" */).then(c => wrapFunctional(c.default || c))
export const WishlistLogin = () => import('../../components/wishlist/WishlistLogin.vue' /* webpackChunkName: "components/wishlist-login" */).then(c => wrapFunctional(c.default || c))
export const WishlistMembership = () => import('../../components/wishlist/WishlistMembership.vue' /* webpackChunkName: "components/wishlist-membership" */).then(c => wrapFunctional(c.default || c))
export const WishlistModal = () => import('../../components/wishlist/WishlistModal.vue' /* webpackChunkName: "components/wishlist-modal" */).then(c => wrapFunctional(c.default || c))
export const WishlistOptionsSelectors = () => import('../../components/wishlist/WishlistOptionsSelectors.vue' /* webpackChunkName: "components/wishlist-options-selectors" */).then(c => wrapFunctional(c.default || c))
export const WishlistProductTile = () => import('../../components/wishlist/WishlistProductTile.vue' /* webpackChunkName: "components/wishlist-product-tile" */).then(c => wrapFunctional(c.default || c))
export const WishlistRemoveAll = () => import('../../components/wishlist/WishlistRemoveAll.vue' /* webpackChunkName: "components/wishlist-remove-all" */).then(c => wrapFunctional(c.default || c))
export const WishlistSelectBox = () => import('../../components/wishlist/WishlistSelectBox.vue' /* webpackChunkName: "components/wishlist-select-box" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
