import { evolve } from "ramda";

import groupByProp from "./group-by-prop";
import { normalizeId, normalizeMetafields } from "./normalize-shopify";

const _getProduct = evolve({
  id: normalizeId,
  description: description => description ?? "",
  descriptionHtml: descriptionHtml => descriptionHtml ?? "",
  productType: productType => productType ?? "",
  priceRange: priceRange =>
    `${priceRange?.maxVariantPrice?.amount}|${priceRange?.minVariantPrice?.amount}`,
  collections: collections =>
    collections.nodes?.map(collection => collection?.handle) ?? [],
  metafields: normalizeMetafields,
});

// normalizes variant
const _getVariant = evolve({
  id: normalizeId,
  product: evolve({ id: normalizeId, metafields: normalizeMetafields }),
  selectedOptions: groupByProp("name"),
  price: price => price?.amount,
  compareAtPrice: compareAtPrice => compareAtPrice?.amount ?? "0",
  metafields: normalizeMetafields,
  images: images => images?.references?.nodes?.map(({ image }) => image) ?? [],
});

function getVariant(variant) {
  const { metafields, product, ...rest } = _getVariant(variant ?? {});
  const { metafields: productMetafields, ...restProduct } = product ?? {};

  return {
    ...rest,
    ...metafields,
    product: { ...productMetafields, ...restProduct },
    ...(variant?.id && { variantId: variant.id }),
  };
}

function getProduct(product) {
  const { metafields, ...rest } = _getProduct(product);

  return { ...metafields, ...rest };
}

export { getVariant, getProduct };
