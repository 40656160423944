import Vue from "vue";

Vue.directive("scroll", {
  inserted(el, binding) {
    const func = function (event) {
      if (binding.value(event, el)) {
        window.removeEventListener("scroll", func);
      }
    };

    window.addEventListener("scroll", func);
  },
});
