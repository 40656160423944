import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
} from "apollo-cache-inmemory";

import errorLink from "~/utils/apollo-error-link";
import introspectionQueryResultData from "~/shopify-fragment-types.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export default function ({ $config, $bugsnag }) {
  return {
    httpEndpoint: `https://${$config.shopifyCheckoutDomain}/api/${$config.shopifyVersion}/graphql.json`,
    httpLinkOptions: {
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": $config.shopifyStorefrontToken,
        Accept: "application/json",
        "accept-language": "en-US",
      },
    },
    persist: false,
    cache: new InMemoryCache({ fragmentMatcher }),
    link: errorLink("Shopify", $bugsnag),
  };
}
