
import { mapState, mapGetters } from "vuex";

export default {
  data: () => ({ email: "", errors: [], loading: false }),

  computed: {
    ...mapState("wishlist", [
      "customer",
      "modalTypes",
      "modalOpenedFrom",
      "addToWishlistProduct",
    ]),
    ...mapGetters("wishlist", ["getItem"]),
  },

  methods: {
    validate() {
      this.errors = [];

      if (!this.email) {
        this.errors.push("Please enter your email address.");
      }

      if (!this.validateEmail(this.email)) {
        this.errors.push("Please check your email address is valid.");
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async handleSubmit() {
      this.loading = true;
      this.validate();

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      let closeModal = true;
      try {
        await this.$store.dispatch("wishlist/login", { email: this.email });
        await this.$store.dispatch("wishlist/postLogin");

        await this.handleSuccess();
        if (this.modalOpenedFrom === "PRODDUCT_TILE") {
          closeModal = false;
        }
      } catch (err) {
        closeModal = false;
        this.errors.push(
          err?.message === "CUSTOMER_NOT_FOUND"
            ? "Looks like you are not a Snooze Member yet, you can easily join via the Join Now button."
            : "Something went wrong..."
        );
      } finally {
        this.loading = false;
        if (closeModal) {
          this.$store.dispatch("wishlist/closeModal");
        }
      }
    },
    async handleSuccess() {
      if (this.modalOpenedFrom === "HEADER") {
        this.$route.path === "/wishlist" && this.$nuxt.refresh();
        this.$router.push("/wishlist");
        return;
      }

      if (!this.addToWishlistProduct) {
        return;
      }

      if (
        this.modalOpenedFrom === "PDP" ||
        this.modalOpenedFrom === "BUNDLE_PRODUCT"
      ) {
        // Add if there was any product pending for wishlist addition
        await this.$store.dispatch(
          "wishlist/addItem",
          this.addToWishlistProduct
        );
        return;
      }

      if (this.modalOpenedFrom === "PRODDUCT_TILE") {
        this.$store.dispatch("wishlist/openModal", {
          type: this.modalTypes.WISHLIST_ADD_MODAL,
          modalOpenedFrom: this.modalOpenedFrom,
          payload: this.addToWishlistProduct,
        });
      }
    },
    openSignupModal() {
      this.$store.dispatch("wishlist/openModal", {
        type: this.modalTypes.MEMBERSHIP_MODAL,
        modalOpenedFrom: this.modalOpenedFrom,
        payload: this.addToWishlistProduct,
      });
    },
  },
};
