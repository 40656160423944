import Vue from "vue";

import responsiveMixin from "~/mixins/responsiveMixin";

Vue.__mY_mIXIn__ = false;
if (!Vue.__mY_mIXIn__) {
  const methods = {
    ...responsiveMixin.methods,
    getImageDimensions(dimensions, newWidth, newHeight) {
      const width = dimensions?.width ?? 200;
      const height = dimensions?.height ?? 200;
      const ar = width / height;

      if (newWidth) {
        return width > newWidth
          ? { width: newWidth, height: newWidth / ar }
          : { width, height };
      }

      return height > newHeight
        ? { width: newHeight * ar, height: newHeight }
        : { width, height };
    },
    canShowCompareAtPrice(priorityCampaign, compareAtPrice, price) {
      return (
        this.hasDiscount(compareAtPrice, price) &&
        priorityCampaign?.campaignTitle !== "intro-price-campaign"
      );
    },
    hasDiscount(compareAtPrice, price) {
      return Number(compareAtPrice) && Number(price) < Number(compareAtPrice);
    },
  };

  const computed = {
    ...responsiveMixin.computed,
    imageWidth() {
      if (this.isMobile) {
        return 768;
      }

      if (this.isTablet) {
        return 1024;
      }

      return 1400;
    },
  };
  Vue.mixin({ ...responsiveMixin, computed, methods });
}
