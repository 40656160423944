import { uniqWith } from "ramda";

import {
  DELIVERY_ESTIMATING_START,
  DELIVERY_ESTIMATING_END,
  ADD_ESTIMATOR_ERROR,
  UPDATE_START,
  UPDATE_END,
} from "./mutations";
import {
  cart,
  cartCreate,
  cartLinesRemove,
  cartLinesAdd,
  cartLinesUpdate,
  cartAttributesUpdate,
  cartBuyerIdentityUpdate,
  getShopMetafields,
} from "./queries.gql";
import { normalizeMetafields } from "~/utils";

function resolveTargetShippingRate(rates) {
  const priorities = [
    "CHGNXTDT1-NEXT", // Next day express
    "CHGNXTDT1", // Express
    "CHGD", // Truck
  ];

  for (let i = 0; i < priorities.length; i++) {
    const rate = rates.find(rate => rate.service_code.includes(priorities[i]));
    if (rate) return rate;
  }

  return rates[0]; // Fall back to first rate
}

const uniqueByKey = uniqWith((a, b) => a.key === b.key);

const normalizeAttributes = (attributes, newAttributes = []) => {
  const newAttributeKeys = newAttributes.map(({ key }) => key);
  const updatedAttributes = [
    ...attributes.filter(({ key }) => !newAttributeKeys.includes(key)),
    ...newAttributes,
  ]
    .map(({ key, value }) => ({ key, value }))
    .filter(({ value }) => Boolean(value));

  return uniqueByKey(updatedAttributes);
};

export default {
  setDeliveryEstimatingStart({ commit }) {
    commit(DELIVERY_ESTIMATING_START);
  },

  setDeliveryEstimatingEnd({ commit }) {
    commit(DELIVERY_ESTIMATING_END);
  },

  async fetchShopMetafields({ commit, state }) {
    if (state.shopMetaFields) {
      return;
    }

    try {
      const { data } = await this.$getShopifyData({ query: getShopMetafields });
      commit("SET_SHOP_METAFIELDS", {
        shopMetaFields: normalizeMetafields(data.shop.metafields),
      });
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },

  async fetchBagVariants({ state, commit }) {
    if (state.bagVariants) {
      return;
    }

    try {
      const url = "api/shopify-admin/bag-variants";
      const {
        data: { assembly, donation },
      } = await this.$axios.get(url);
      commit("SET_BAG_VARIANTS", { assembly, donation });
    } catch (err) {
      this.$bugsnag.notify(err);
    }
  },

  async estimateDelivery(
    { state, commit, dispatch, getters },
    { postcode, suburb }
  ) {
    // State already updating, don't do anything
    if (state.updating) {
      console.log("State already updating, will not update");
      return;
    }

    if (!postcode) {
      return;
    }

    // Apply postcode for quickship
    dispatch("quickship/setPostcode", { postcode }, { root: true });
    dispatch("quickship/setSuburb", { suburb }, { root: true });

    // Set updating flag to prevent more updates
    commit(UPDATE_START);

    // Set updating flag for spinner
    let response = null;
    const url = `${this.$config.snoozeEcom}/shipping-simple`;
    const body = {
      suburb,
      postcode: String(postcode),
      is_member: getters.isSnoozeMember,
      items: getters.shippingSimpleItems,
    };

    try {
      // Fetch shipping options
      ({ data: response } = await this.$axios.post(url, body));
    } catch (err) {
      commit(ADD_ESTIMATOR_ERROR, {
        error: "Sorry, delivery isn’t available in your area.",
      });
      dispatch("setShippingRates", { rates: [] });
      dispatch("selectShippingRate", { shippingRate: null });
      commit(UPDATE_END);
      this.$bugsnag.notifyApiError({ err, body, url });
      return;
    }

    // Fetch first shipping option with a price
    const pricedOption = response.rates.find(rate => {
      return rate.total_price > 0 || rate.service_name.includes("Free");
    });

    // No option available, set error
    if (!pricedOption) {
      commit(ADD_ESTIMATOR_ERROR, {
        error: "Sorry, delivery isn’t available in your area.",
      });
    }

    const eligible =
      response.rates.filter(rate => rate.service_code.includes("CHGNXTD"))
        .length > 0;
    dispatch("quickship/setEligible", { eligible }, { root: true });

    // Calculate visible shipping rates
    const visibleShippingRates = response.rates.filter(
      rate => !rate.service_code.includes("POA")
    );

    dispatch("setShippingRates", { rates: visibleShippingRates });

    dispatch("addPickupToShippingRates");

    dispatch("setHasAssembly", { hasAssembly: response.hasAssembly });

    dispatch("setHasFilteredQuickship", {
      hasFilteredQuickship: response.hasFilteredQuickship,
    });

    if (process.client) {
      // In quickship init this is dispatched, to avoid duplication call it only on client side
      await dispatch("quickship/resolveQuickshipItems", null, { root: true });
    }

    // Select shipping rate if unselected or not included in new result
    if (
      !state.selectedShippingRate ||
      !state.shippingRates.find(
        rate => rate.service_code === state.selectedShippingRate.service_code
      )
    ) {
      dispatch("selectShippingRate", {
        shippingRate: resolveTargetShippingRate(state.shippingRates),
      });
    }

    commit(UPDATE_END);
  },

  selectShippingRate({ commit }, { shippingRate }) {
    commit("selectShippingRate", { shippingRate });
  },

  setShippingRates({ commit }, { rates }) {
    commit("setShippingRates", { rates });
  },

  setHasAssembly({ commit }, { hasAssembly }) {
    commit("setHasAssembly", { hasAssembly });
  },

  setHasFilteredQuickship({ commit }, { hasFilteredQuickship }) {
    commit("setHasFilteredQuickship", { hasFilteredQuickship });
  },

  addPickupToShippingRates({ commit, getters }) {
    commit(
      "ADD_PICKUP_TO_SHIPPING_RATES",
      getters.assemblyLineItems.length !== 0
    );
  },

  // Sharing card message update
  setShareCardMailMessage({ commit }) {
    commit("SET_MAIL_SEND_MESSAGE", true);
    setTimeout(() => {
      commit("SET_MAIL_SEND_MESSAGE", false);
    }, 5000);
  },

  async estimateDeliveryWithLoader({ dispatch, rootState }) {
    dispatch("setDeliveryEstimatingStart");
    await dispatch("estimateDelivery", {
      postcode: rootState.quickship.postcode,
      suburb: rootState.quickship.suburb,
    });
    dispatch("setDeliveryEstimatingEnd");
  },

  SET_OFFER_DATA({ commit }, { offerTags, showOffer }) {
    commit("SET_OFFER_DATA", { offerTags, showOffer });
  },

  showDonation({ commit }) {
    commit("setShowDonation");
  },

  async GET_CART({ commit, dispatch }, cartId) {
    if (!cartId) {
      return;
    }

    try {
      const { data } = await this.$getShopifyData({
        query: cart,
        variables: { cartId },
      });

      commit("SET_CART", data.cart);
      dispatch("SET_MINI_CART", data.cart);
    } catch (err) {
      commit("SET_CART_ERROR");
    }
  },

  CLEAR_CART({ commit }) {
    commit("CLEAR_CART");
  },

  // Creates a cart in Shopify, and then stores the results as cart
  async CREATE_CART({ commit, dispatch, getters }, lines = []) {
    const variables = {
      input: {
        attributes: [{ key: "_is_express_checkout", value: "false" }],
        lines: lines.map(({ attributes = [], ...rest }) => ({
          ...rest,
          attributes: normalizeAttributes(
            [...attributes, getters.membershipAttr].filter(Boolean)
          ),
        })),
      },
    };

    const cartData = await this.$setShopifyData({
      mutation: cartCreate,
      variables,
    });

    const cart = cartData.data.cartCreate?.cart ?? {};
    commit("SET_CART", cart);
    dispatch("SET_MINI_CART", cart);
    this.app.$cart.setCartId(cart.id);
  },

  /**
   * Add lines to the cart
   */
  async ADD_CART_LINES({ state, commit, dispatch, getters }, lines = []) {
    // normalize attributes according to shopify lines
    const normalizedLines = lines.map(
      ({ merchandiseId, quantity = 1, attributes = [] }) => ({
        merchandiseId,
        quantity,
        attributes: normalizeAttributes(
          [...attributes, getters.membershipAttr].filter(Boolean)
        ),
      })
    );

    const cartData = await this.$setShopifyData({
      mutation: cartLinesAdd,
      variables: {
        cartId: state.cart.id,
        lines: normalizedLines,
      },
    });

    const cart = cartData.data.cartLinesAdd?.cart ?? {};

    commit("SET_CART", cart);
    dispatch("SET_MINI_CART", cart);
  },

  /**
   * update cart lines
   */
  async UPDATE_CART_LINES({ state, commit, dispatch, getters }, lines = []) {
    lines = lines.map(({ attributes: newAttributes = [], ...rest } = {}) => {
      const { attributes } = getters.lines.find(line => line.id === rest.id);

      return {
        attributes: normalizeAttributes(attributes, newAttributes),
        ...rest,
      };
    });

    const cartData = await this.$setShopifyData({
      mutation: cartLinesUpdate,
      variables: { cartId: state.cart.id, lines },
    });

    const cart = cartData.data.cartLinesUpdate.cart ?? {};

    commit("SET_CART", cart);
    dispatch("SET_MINI_CART", cart);
  },

  async REMOVE_CART_LINES({ state, commit, dispatch }, lineIds = []) {
    const cartData = await this.$setShopifyData({
      mutation: cartLinesRemove,
      variables: { cartId: state.cart.id, lineIds },
    });

    const cart = cartData.data.cartLinesRemove?.cart ?? {};

    commit("SET_CART", cart);
    dispatch("SET_MINI_CART", cart);
  },

  async REFRESH_CART({ state, commit, dispatch }) {
    const updatedAttributes = normalizeAttributes(state.cart.attributes, [
      { key: "_updated_at", value: `${new Date().getTime()}` },
    ]);

    const cartData = await this.$setShopifyData({
      mutation: cartAttributesUpdate,
      variables: {
        cartId: state.cart.id,
        attributes: updatedAttributes,
        all: true,
      },
    });

    const cart = cartData.data.cartAttributesUpdate?.cart ?? {};

    commit("SET_CART", cart);
    dispatch("SET_MINI_CART", cart);
  },

  // updates the custom attributes of the cart provided in the following format
  // [{key: "key", value: "value"}]
  async UPDATE_ATTRIBUTES({ state, commit }, attributes = []) {
    const updatedAttributes = normalizeAttributes(
      state.cart.attributes,
      attributes
    );

    await this.$setShopifyData({
      mutation: cartAttributesUpdate,
      variables: {
        cartId: state.cart.id,
        attributes: updatedAttributes,
      },
    });

    commit("SET_CART_ATTRIBUTES", { attributes: updatedAttributes });
  },

  SET_MINI_CART({ commit }, { id, cost, lines }) {
    const lineItemsCount = (lines?.nodes ?? [])
      .filter(item => {
        return ![
          "Snooze Assembly Service",
          "Donation to The Smith Family",
        ].includes(item.merchandise?.product?.title);
      })
      .reduce((acc, val) => acc + val.quantity, 0);

    commit("SET_MINI_CART", {
      id,
      totalPrice: cost.totalAmount?.amount,
      lineItemsCount,
    });
  },

  async UPDATE_CART_BUYER_IDENTITY({ state, commit }, buyerIdentity) {
    try {
      await this.$setShopifyData({
        mutation: cartBuyerIdentityUpdate,
        variables: {
          cartId: state.cart.id,
          buyerIdentity,
        },
      });
    } catch (err) {
      commit("SET_CART_ERROR");
    }
  },

  async ADD_MEMBERSHIP_ATTRS({ getters, dispatch }, { memberId, email }) {
    const itemsWithMembershipAttrs = getters.lines.map(
      ({ id, quantity, variant, attributes }) => ({
        id,
        quantity,
        merchandiseId: variant.variantId,
        attributes: [
          ...attributes.filter(attr => attr.key !== "_membership_details"),
          {
            key: "_membership_details",
            value: JSON.stringify({ email, memberId: String(memberId) }),
          },
        ],
      })
    );

    if (itemsWithMembershipAttrs.length === 0) {
      return;
    }

    await dispatch("UPDATE_CART_LINES", itemsWithMembershipAttrs);
  },
};
