function getPreviewCookie($cookies) {
  return $cookies.get("io.prismic.preview", { parseJSON: false });
}

async function _getPrismicData({
  query,
  variables,
  client,
  $cookies,
  fetchPolicy,
}) {
  const previewCookie = getPreviewCookie($cookies);
  const context = previewCookie
    ? { context: { headers: { "Prismic-ref": previewCookie } } }
    : {};

  try {
    const { data } = await client.query({
      query,
      variables: { lang: "en-in", ...variables },
      ...context,
      ...(fetchPolicy && { fetchPolicy }),
    });

    return data;
  } catch (err) {
    console.error(err);
    throw new Error("Oops Something went wrong...", { cause: err });
  }
}

async function getPrismicApi({ $config, $bugsnag, $cookies }) {
  const { default: Prismic } = await import("@prismicio/client");
  const prismicApi = await Prismic.getApi($config.prismicAPIEndPoint);

  return {
    predicateQuery: (predicates, options) => {
      const previewCookie = getPreviewCookie($cookies);
      const updatedOptions = previewCookie
        ? { ref: previewCookie, ...options }
        : options;

      try {
        const response = prismicApi.query(predicates, updatedOptions);
        return response;
      } catch (err) {
        $bugsnag.notify(err, e => {
          e.addMetadata("predicates", predicates);
          e.addMetadata("options", updatedOptions);
        });
      }
    },
    getMasterRef: () => {
      return prismicApi.masterRef.ref;
    },
    Predicates: Prismic.Predicates,
  };
}

export default async function ({ app, $config, $cookies, $bugsnag }, inject) {
  /**
  It will run in both the cases to fetch data for prismic preview and also for normal cases.
  We have to rename the body as slices( it is required) in gql query.
  */
  const client = app.apolloProvider.clients.prismic;
  const getPrismicData = ({ query, variables = {}, fetchPolicy } = {}) =>
    _getPrismicData({
      query,
      variables,
      $cookies,
      client,
      fetchPolicy,
    });

  inject("getPrismicData", getPrismicData);
  inject("prismicApi", await getPrismicApi({ $config, $bugsnag, $cookies }));
}
