// Shopify requires you to persist the CartId.
// This is to persist the Cart Id to SSR storage.
// https://github.com/microcipcip/cookie-universal/tree/master/packages/cookie-universal-nuxt

export default ({ app, $config, isDev }, inject) => {
  const name =
    $config.appEnv !== "production" ? `cartId=${$config.appEnv}` : "cartId";
  const cookieName = encodeURIComponent(name);

  const cart = {
    getCartId: () => {
      return app.$cookies.get(cookieName);
    },
    setCartId: cartId => {
      const extraOptions = isDev
        ? {}
        : {
            sameSite: "lax",
            domain: $config.domainName,
            secure: true,
          };

      app.$cookies.set(cookieName, cartId, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365 * 5, // 5 years, no expiry,
        ...extraOptions,
      });
    },
    clearCart: () => {
      app.$cookies.remove(cookieName, { domain: $config.domainName });
    },
  };

  inject("cart", cart);
};
