const LANGUAGE_CODE = "en-au";
const CHANNEL = "web";
const API_VERSION = "v1_4";

let isScriptLoaded = false;
function addScript(trackingData) {
  return new Promise((resolve, reject) => {
    if (isScriptLoaded) {
      resolve({ sendAjax: true });
      return;
    }

    window.PeeriusCallbacks.track = trackingData;

    let scriptElement = document.createElement("script");
    scriptElement.src = "https://snoozeprod.peerius.episerver.net/tracker/peerius.page";
    scriptElement.type = "text/javascript";
    scriptElement.defer = true;
    scriptElement.setAttribute("hid", "optimizely-script");
    scriptElement.onload = function onOptimizelyLoad() {
      console.log("OPTIMIZELY ONLOAD CALLED");
      isScriptLoaded = true;
      resolve({ sendAjax: false });
    };

    const headElement = document.querySelector("body");
    headElement.append(scriptElement);
  });
}

export default function ({ $config, store }, inject) {
  window.PeeriusCallbacks = {
    smartRecs(widgets) {
      console.log(widgets);

      store.dispatch("app/setRecommendedWidgets", { widgets });
    },
    track: {},
    apiVersion: API_VERSION,
  };

  inject("optimizely", {
    track(data) {
      store.dispatch("app/setRecommendedWidgets", { widgets: [] });

      console.log("OPTIMIZELY TRACKING DATA CALLED");
      const trackingData = {
        site: "snoozeprod",
        lang: LANGUAGE_CODE,
        channel: CHANNEL,
        smartRecs: {
          showAttributes: ["*"],
        },
        ...(data ?? {}),
      };

      addScript(trackingData).then(({ sendAjax }) => {
        if (!sendAjax) {
          return;
        }

        const json = encodeURIComponent(JSON.stringify(trackingData));
        window?.Peerius?.sendAjax(
          `rest.pagex?jd=${json}&location=${LANGUAGE_CODE}`
        );
      });
    },
    clickTracker(id) {
      window?.Peerius?.smartRecsSendClick(id);
    },
  });
}
