import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _04f710f4 = () => interopDefault(import('../pages/become-a-member/index.vue' /* webpackChunkName: "pages/become-a-member/index" */))
const _1ea1a364 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _46a7d896 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _7bcdd2ce = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _4375dd8a = () => interopDefault(import('../pages/contact-us/success.vue' /* webpackChunkName: "pages/contact-us/success" */))
const _4ba810a0 = () => interopDefault(import('../pages/preferences/index.vue' /* webpackChunkName: "pages/preferences/index" */))
const _4aeac020 = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _ef8d8924 = () => interopDefault(import('../pages/slice-simulator.vue' /* webpackChunkName: "pages/slice-simulator" */))
const _4ebcdd33 = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _05d6e337 = () => interopDefault(import('../pages/work-with-us/index.vue' /* webpackChunkName: "pages/work-with-us/index" */))
const _49e2edd4 = () => interopDefault(import('../pages/blogs/inspiration/index.vue' /* webpackChunkName: "pages/blogs/inspiration/index" */))
const _797c5d98 = () => interopDefault(import('../pages/products/search.vue' /* webpackChunkName: "pages/products/search" */))
const _35c42ce1 = () => interopDefault(import('../pages/store/store-locator.vue' /* webpackChunkName: "pages/store/store-locator" */))
const _6b2070ba = () => interopDefault(import('../pages/blogs/inspiration/tagged/_tag/index.vue' /* webpackChunkName: "pages/blogs/inspiration/tagged/_tag/index" */))
const _0314bb49 = () => interopDefault(import('../pages/blogs/inspiration/_handle.vue' /* webpackChunkName: "pages/blogs/inspiration/_handle" */))
const _382f053a = () => interopDefault(import('../pages/preview/campaign-sale/_handle.vue' /* webpackChunkName: "pages/preview/campaign-sale/_handle" */))
const _6d6335f6 = () => interopDefault(import('../pages/campaign/_handle/index.vue' /* webpackChunkName: "pages/campaign/_handle/index" */))
const _748d9196 = () => interopDefault(import('../pages/collections/_handle/index.vue' /* webpackChunkName: "pages/collections/_handle/index" */))
const _53836164 = () => interopDefault(import('../pages/pages/_handle/index.vue' /* webpackChunkName: "pages/pages/_handle/index" */))
const _6e8bcd6c = () => interopDefault(import('../pages/products/_handle/index.vue' /* webpackChunkName: "pages/products/_handle/index" */))
const _0f325fcb = () => interopDefault(import('../pages/static-pages/_handle/index.vue' /* webpackChunkName: "pages/static-pages/_handle/index" */))
const _16b33d41 = () => interopDefault(import('../pages/store/_handle/index.vue' /* webpackChunkName: "pages/store/_handle/index" */))
const _797ae551 = () => interopDefault(import('../pages/collections/_handle/products.vue' /* webpackChunkName: "pages/collections/_handle/products" */))
const _b8a7a922 = () => interopDefault(import('../pages/collections/_handle/_facet/_facetValue.vue' /* webpackChunkName: "pages/collections/_handle/_facet/_facetValue" */))
const _51272312 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/become-a-member",
    component: _04f710f4,
    name: "become-a-member"
  }, {
    path: "/cart",
    component: _1ea1a364,
    name: "cart"
  }, {
    path: "/contact-us",
    component: _46a7d896,
    children: [{
      path: "",
      component: _7bcdd2ce,
      name: "contact-us"
    }, {
      path: "success",
      component: _4375dd8a,
      name: "contact-us-success"
    }]
  }, {
    path: "/preferences",
    component: _4ba810a0,
    name: "preferences"
  }, {
    path: "/preview",
    component: _4aeac020,
    name: "preview"
  }, {
    path: "/slice-simulator",
    component: _ef8d8924,
    name: "slice-simulator"
  }, {
    path: "/wishlist",
    component: _4ebcdd33,
    name: "wishlist"
  }, {
    path: "/work-with-us",
    component: _05d6e337,
    name: "work-with-us"
  }, {
    path: "/blogs/inspiration",
    component: _49e2edd4,
    name: "blogs-inspiration"
  }, {
    path: "/products/search",
    component: _797c5d98,
    name: "products-search"
  }, {
    path: "/store/store-locator",
    component: _35c42ce1,
    name: "store-store-locator"
  }, {
    path: "/blogs/inspiration/tagged/:tag",
    component: _6b2070ba,
    name: "blogs-inspiration-tagged-tag"
  }, {
    path: "/blogs/inspiration/:handle",
    component: _0314bb49,
    name: "blogs-inspiration-handle"
  }, {
    path: "/preview/campaign-sale/:handle?",
    component: _382f053a,
    name: "preview-campaign-sale-handle"
  }, {
    path: "/campaign/:handle",
    component: _6d6335f6,
    name: "campaign-handle"
  }, {
    path: "/collections/:handle",
    component: _748d9196,
    name: "collections-handle"
  }, {
    path: "/pages/:handle",
    component: _53836164,
    name: "pages-handle"
  }, {
    path: "/products/:handle",
    component: _6e8bcd6c,
    name: "products-handle"
  }, {
    path: "/static-pages/:handle",
    component: _0f325fcb,
    name: "static-pages-handle"
  }, {
    path: "/store/:handle",
    component: _16b33d41,
    name: "store-handle"
  }, {
    path: "/collections/:handle?/products",
    component: _797ae551,
    name: "collections-handle-products"
  }, {
    path: "/collections/:handle?/:facet/:facetValue?",
    component: _b8a7a922,
    name: "collections-handle-facet-facetValue"
  }, {
    path: "/",
    component: _51272312,
    name: "index"
  }],

  parseQuery: function(queryString) {
      return require("qs").parse(queryString);
    },
  stringifyQuery: function(object) {
      const queryString = require("qs").stringify(object, {
        arrayFormat: "repeat"
      });
      return queryString ? "?" + queryString : "";
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
