export default {
  computed: {
    isCustomisable() {
      return this.tags?.includes("customisable");
    },
    mattressOnly() {
      return (
        this.tags?.includes("mattresses-only") ||
        this.tags?.includes("ensemble")
      );
    },
    mattressesOnly() {
      return this.tags?.includes("mattresses-only");
    },
    isHeadBoardOrSofaBed() {
      return (
        this.tags?.includes("headboards") || this.tags?.includes("sofa-beds")
      );
    },
    headboards() {
      return this.tags?.includes("headboards");
    },
    sofaBeds() {
      return this.tags?.includes("sofa-beds");
    },
    beds() {
      return this.tags?.find(tag => tag === "beds" || tag === "Beds");
    },
    isReadyToShip() {
      return this.tags?.includes("ready-to-ship");
    },
    isMultiVariant() {
      return this.tags?.some(tag => tag.includes("mv:"));
    },
    oneProductMultipleFeels() {
      return this.tags?.includes("one-product-multiple-feels");
    },
    isExclusive() {
      return this.tags?.find(tag => tag == "exclusive-to-snooze");
    },
    isIntroPrice() {
      return this.tags?.includes("intro-price");
    },
    bedsideTables() {
      return this.tags?.includes("bedside-tables");
    },
    lowboys() {
      return this.tags?.includes("lowboys");
    },
    mirrors() {
      return this.tags?.includes("mirrors");
    },
    tallboys() {
      return this.tags?.includes("tallboys");
    },
    desks() {
      return this.tags?.includes("desks");
    },
    blanketBoxes() {
      return this.tags?.includes("blanket-boxes");
    },
    dresserWithMirror() {
      return this.tags?.includes("dresser-with-mirror");
    },
    dresserWithoutMirror() {
      return this.tags?.includes("dresser-without-mirror");
    },
    bookcases() {
      return this.tags?.includes("bookcases");
    },
    isStylefitProduct() {
      return this.tags?.includes("mystyle-mydesign-stylefit-render");
    },
    isBundleProduct() {
      return this.tags?.includes("bundles-and-bedroomsuites");
    },
  },
};
