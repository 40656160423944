
import { mapState } from "vuex";

export default {
  props: {
    src: { validator: v => v == null || typeof v == "string", required: true },
    alt: { type: String, default: "" },
    width: { type: [String, Number], default: undefined },
    height: { type: [String, Number], default: undefined },
    fit: { type: String, default: undefined },
    modifiers: { type: Object, default: () => {} },
    provider: { type: String, default: undefined },
    sizes: { type: String, default: undefined },
    legacyFormat: { type: String, default: undefined },
  },

  computed: {
    ...mapState(["siteConfig"]),
    imgSizes() {
      return this.sizes ? this.sizes : `xs:${this.width}px`;
    },
  },
};
