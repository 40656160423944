import { render, staticRenderFns } from "./default.vue?vue&type=template&id=333609de&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=333609de&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "333609de",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppTopBanner: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppTopBanner.vue').default,SiteHeader: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/layout/SiteHeader.vue').default,MainCampaignBanner: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/layout/MainCampaignBanner.vue').default,WishlistLogin: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/wishlist/WishlistLogin.vue').default,WishlistModal: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/wishlist/WishlistModal.vue').default})
