const middleware = {}

middleware['cart'] = require('../middleware/cart.js')
middleware['cart'] = middleware['cart'].default || middleware['cart']

middleware['check-index'] = require('../middleware/check-index.js')
middleware['check-index'] = middleware['check-index'].default || middleware['check-index']

middleware['is-collection-exist'] = require('../middleware/is-collection-exist.js')
middleware['is-collection-exist'] = middleware['is-collection-exist'].default || middleware['is-collection-exist']

middleware['prismic-api-ref'] = require('../middleware/prismic-api-ref.js')
middleware['prismic-api-ref'] = middleware['prismic-api-ref'].default || middleware['prismic-api-ref']

middleware['searchspring'] = require('../middleware/searchspring.js')
middleware['searchspring'] = middleware['searchspring'].default || middleware['searchspring']

middleware['seo-data'] = require('../middleware/seo-data.js')
middleware['seo-data'] = middleware['seo-data'].default || middleware['seo-data']

export default middleware
