import Vue from "vue";

/**
 * Register Vue Filters
 */
Vue.filter("currency", currency);
Vue.filter("formatCurrency", formatCurrency);
Vue.filter("formatDate", formatDate);

const digitsRE = /(\d{3})(?=\d)/g;
const shopifyPriceInCents = 100;

function currency(value, isPriceInCents) {
  if (isPriceInCents) {
    value = value / shopifyPriceInCents;
    return convertToCurrency(value);
  }

  return convertToCurrency(value);
}

function formatCurrency(price, decimalPlace, showZero = false, roundUp = true) {
  const shouldShowZero = price || (price == 0 && showZero == true);
  const calculatedPrice = roundUp ? Math.ceil(price) : price;

  if (shouldShowZero && decimalPlace) {
    return `$${new Intl.NumberFormat("en", {
      minimumFractionDigits: decimalPlace,
      maximumFractionDigits: decimalPlace,
    }).format(calculatedPrice)}`;
  }

  if (shouldShowZero) {
    return `$${new Intl.NumberFormat().format(calculatedPrice)}`;
  }
  return "";
}

function convertToCurrency(value, currency, decimals) {
  value = parseFloat(value);

  if (!isFinite(value) || (!value && value !== 0)) return "";

  currency = currency != null ? currency : "$";
  decimals = decimals != null ? decimals : 2;

  let stringified = Math.abs(value).toFixed(decimals);

  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  const i = _int.length % 3;
  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? "," : "") : "";
  const _float = decimals ? stringified.slice(-1 - decimals) : "";
  const sign = value < 0 ? "-" : "";
  return (
    sign + currency + head + _int.slice(i).replace(digitsRE, "$1,") + _float
  );
}

function formatDate(dateString, monthFormat = "long") {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-AU", {
    year: "numeric",
    month: monthFormat,
    day: "numeric",
  });
}
