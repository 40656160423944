import "./middleware.js";

async function getAuthToken(app) {
  const token = app.store.state.wishlist.token;

  if (token?.expiryDate > Date.now()) {
    return token.access_token;
  }

  const authUrl = "https://www.snooze.com.au/wishlist/api/auth";
  const { data } = await app.$axios.get(authUrl);
  app.store.dispatch("wishlist/setToken", data);
  return data.token.access_token;
}

export default ({ app }, inject) => {
  const wishlist = {
    async request(url, { method, body } = { method: "GET" }) {
      if ((method === "POST" || method === "PUT") && body == null) {
        throw new Error("Post should have body also.");
      }

      try {
        const token = await getAuthToken(app);
        const { data, status } = await app.$axios({
          url,
          method,
          headers: {
            "X-TWC-Tenant": "snooze-au",
            Authorization: `Bearer ${token}`,
          },
          ...((method === "POST" || method === "PUT") && { data: body }),
          validateStatus: function (status) {
            return status === 404 ? true : status >= 200 && status < 300;
          },
        });

        return status === 404 ? null : data;
      } catch (err) {
        app.$bugsnag.notify(err);
        throw err;
      }
    },
    getWishlistsById(customerId) {
      const url = `https://api.au-aws.thewishlist.io/services/wsservice/api/wishlists/lookup?customerId=${customerId}&pageSize=1`;
      return this.request(url);
    },
    createWishlistsById(customerId) {
      const url = `https://api.au-aws.thewishlist.io/services/wsservice/api/wishlists`;
      const body = { customerId, name: "Default wishlist" };
      return this.request(url, { body, method: "POST" });
    },
    deleteWishlistById(id) {
      const url = `https://api.au-aws.thewishlist.io/services/wsservice/api/wishlists/?wishlistI=${id}`;
      const body = { id, deleted: true };
      return this.request(url, { body, method: "PUT" });
    },
    getWishlistItemsById(id, lastItemId) {
      const url = `https://api.au-aws.thewishlist.io/services/wsservice/api/wishlist/items?id=${id}&lastItemId=${lastItemId}&pageSize=50`;
      return this.request(url);
    },
    addItem({ wishlistId, productId, variantId, itemRef, attributeGroups }) {
      const url = "https://api.au-aws.thewishlist.io/services/wsservice/api/wishlist/items";
      const body = {
        wishlistId,
        attributeGroups,
        wishlistItemRef: itemRef,
        product: { productRef: productId, selectedVariantRef: variantId },
      };
      console.log({ body });
      return this.request(url, { body, method: "POST" });
    },
    removeItemByRef(itemRef) {
      const url = `https://api.au-aws.thewishlist.io/services/wsservice/api/wishlist/items/${itemRef}/ref`;
      return this.request(url, { method: "DELETE" });
    },
    updateItemByRef({
      id,
      itemRef,
      wishlistId,
      productId,
      oldSelectedVariantId,
      selectedVariantId,
    }) {
      const url = `https://api.au-aws.thewishlist.io/services/wsservice/api/wishlist/items`;
      const body = {
        id,
        wishlistId,
        wishlistItemRef: itemRef,
        product: {
          productRef: productId,
          oldVariantRef: oldSelectedVariantId,
          selectedVariantRef: selectedVariantId,
        },
      };

      return this.request(url, { body, method: "PUT" });
    },
    login(email) {
      const url = `https://www.snooze.com.au/wishlist/api/login?email=${email}`;
      return this.request(url);
    },
    signup(body) {
      const url = "https://www.snooze.com.au/wishlist/api/signup";
      return this.request(url, { body, method: "POST" });
    },
  };

  inject("wishlist", wishlist);
};
