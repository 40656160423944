export default function ({ $searchspring }) {
  if (!$searchspring.getIsuid()) {
    $searchspring.setIsuid();
  }

  if (!$searchspring.getSsUserId()) {
    $searchspring.setSsUserId();
  }

  if (!$searchspring.getSsSessionIdNamespace()) {
    $searchspring.setSsSessionIdNamespace();
  }
}
