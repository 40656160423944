import { normalizeCart } from "~/utils";

export const UPDATE_START = "UPDATE_START";
export const UPDATE_END = "UPDATE_END";
export const DELIVERY_ESTIMATING_START = "DELIVERY_ESTIMATING_START";
export const DELIVERY_ESTIMATING_END = "DELIVERY_ESTIMATING_END";
export const ADD_ESTIMATOR_ERROR = "ADD_ESTIMATOR_ERROR";
export const CLEAR_ESTIMATOR_ERRORS = "CLEAR_ESTIMATOR_ERRORS";

export default {
  SET_SHOP_METAFIELDS(state, { shopMetaFields }) {
    state.shop = shopMetaFields;
  },
  SET_BAG_VARIANTS(state, { assembly, donation }) {
    state.bagVariants = { assembly, donation };
  },
  // Set updating flag
  [UPDATE_START](state) {
    state.updating = true;
  },
  // Clear updating flag
  [UPDATE_END](state) {
    state.updating = false;
  },
  [DELIVERY_ESTIMATING_START](state) {
    state.deliveryEstimating = true;
  },
  [DELIVERY_ESTIMATING_END](state) {
    state.deliveryEstimating = false;
  },
  [ADD_ESTIMATOR_ERROR](state, { error }) {
    state.estimatorErrors.push(error);
  },
  [CLEAR_ESTIMATOR_ERRORS](state) {
    state.estimatorErrors = [];
  },
  setShippingRates(state, { rates }) {
    state.shippingRates = rates;
  },
  selectShippingRate(state, { shippingRate }) {
    state.selectedShippingRate = shippingRate;
  },
  setHasAssembly(state, { hasAssembly }) {
    state.hasAssembly = hasAssembly;
  },
  setHasFilteredQuickship(state, { hasFilteredQuickship }) {
    state.hasFilteredQuickship = hasFilteredQuickship;
  },
  ADD_PICKUP_TO_SHIPPING_RATES(state, hasAssembly) {
    const pickupRate = state.shippingRates.find(
      rate => rate.service_code === "CUSTPICKUP"
    );
    if (!pickupRate && !hasAssembly) {
      const item = {
        description:
          "Pickup your order when it is available from a Snooze store of your choice.",
        service_code: "CUSTPICKUP",
        service_name: "Pickup",
        store: null,
        total_price: 0,
      };
      state.shippingRates = [item, ...state.shippingRates];
    }
  },
  SET_MAIL_SEND_MESSAGE(state, isSuccess) {
    state.showShareEmailSentMessage = isSuccess;
  },
  SET_OFFER_DATA(state, { offerTags, showOffer }) {
    state.offerTags = offerTags;
    state.showOffer = showOffer;
  },
  setShowDonation(state) {
    state.showDonation = true;
  },

  SET_CART(state, cart) {
    if (cart == null) {
      state.cart = cart;
      return;
    }

    const checkoutUrl = cart.checkoutUrl ?? "";
    let url = { href: "" };
    if (checkoutUrl != "") {
      url = new URL(checkoutUrl);
      const dmI = this.$cookies.get("dm_i");
      dmI && url.searchParams.set("dm_i", dmI);
    }

    const normalizedCart = normalizeCart(cart);
    const subTotal = cart.cost.subtotalAmount.amount;
    state.cart = {
      ...normalizedCart,
      lines: normalizedCart.lines?.nodes ?? [],
      checkoutUrl: url.href,
      subTotal,
    };
  },

  SET_CART_ERROR(state) {
    state.cartError = true;
  },

  SET_MINI_CART(state, { id, totalPrice, lineItemsCount }) {
    state.miniCart = { id, totalPrice, lineItemsCount };
  },

  CLEAR_CART(state) {
    state.cart = null;
    state.miniCart = {
      id: null,
      lineItemsCount: 0,
      totalPrice: 0,
    };
    this.app.$cart.clearCart();
  },

  SET_CART_ATTRIBUTES(state, { attributes }) {
    state.cart = { ...state.cart, attributes };
  },
};
