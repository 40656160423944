const normalizeId = id => id?.split("/").slice(-1)[0];

const normalizeMetafields = (metafields = []) =>
  metafields
    .filter(Boolean)
    .map(({ key, value, type }) => ({
      key,
      value: type.includes("list.")
        ? JSON.parse(value)
        : value?.trim?.() ?? value,
    }))
    .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {});

export { normalizeId, normalizeMetafields };
