// import { has } from "ramda";
import { createServerRootMixin } from "vue-instantsearch";
import algoliasearch from "algoliasearch/lite";
import getStateMapping, {
  facetAttributes,
} from "~/utils/route-state-mapping-utils";

function nuxtRouter(vueRouter) {
  return {
    read() {
      // Removing extra parameters
      const query = vueRouter.currentRoute.query ?? {};
      const filteredEntries = Object.entries(query).filter(([attribute]) =>
        facetAttributes.includes(attribute)
      );
      return Object.fromEntries(filteredEntries);
    },
    readExtraParams() {
      const query = vueRouter.currentRoute.query ?? {};
      const filteredEntries = Object.entries(query).filter(
        ([attribute]) => !facetAttributes.includes(attribute)
      );
      return Object.fromEntries(filteredEntries);
    },
    write(routeState) {
      // if we get routeState's variable as undefined then
      // to stop it from going further into function which
      // can lead to change of url back to old route to prevent
      // that this line has been added(Was facing an issue when
      // redirecting from PLP to PLP using search).

      if (Object.values(routeState).every(val => !val)) {
        return;
      }
      // if routeState is empty it means state is disposed and new state is
      // created. It occurs when a user comes from seacrh bar in a PLP page
      // at that time routestate is empty and currentRoute have a query
      // the following line handles the above case
      const currentRoute = vueRouter.currentRoute;
      if (
        currentRoute.path === "/products/search" &&
        this.createURL(routeState) === "/products/search"
      ) {
        return;
      }
      if (currentRoute.name === "products-handle") {
        return;
      }
      if (this.createURL(routeState) === this.createURL(this.read())) {
        // Only push a new entry if the URL changed (avoid duplicated entries in the history)
        return;
      }
      vueRouter.push({
        query: { ...this.readExtraParams(), ...routeState },
      });
    },
    createURL(routeState) {
      return vueRouter.resolve({ query: routeState }).href;
    },
    onUpdate(cb) {
      if (typeof window === "undefined") return;
      this._onPopState = event => {
        const routeState = event.state;
        // On initial load, the state is read from the URL without
        // update. Therefore, the state object isn't present. In this
        // case, we fallback and read the URL.
        if (!routeState) {
          console.log("update");
          cb(this.read());
        } else {
          // was getting only state key so
          // obtained from route
          console.log({ popppp: routeState });
          cb(routeState);
        }
      };
      window.addEventListener("popstate", this._onPopState);
    },
    dispose() {
      if (typeof window === "undefined") return;

      window.removeEventListener("popstate", this._onPopState);
    },
  };
}

// const hasEmptyFacetFilter = params =>
//   has("facetFilters", params) ? !params.facetFilters : false;

// const hasEmptyQueryFilter = params =>
//   has("query", params) ? !params.query : false;

let searchClient = null;
function getAlgoliaClient(algoliaAppId, algoliaKey, bugsnag) {
  const algoliaClient = algoliasearch(algoliaAppId, algoliaKey);

  if (!searchClient) {
    searchClient = {
      search(requests) {
        // if (requests.every(({ params }) => hasEmptyQueryFilter(params))) {
        //   return Promise.resolve({
        //     results: requests.map(() => ({
        //       hits: [],
        //       nbHits: 0,
        //       nbPages: 0,
        //       page: 0,
        //       processingTimeMS: 0,
        //     })),
        //   });
        // }

        return algoliaClient.search(requests).catch(err => bugsnag.notify(err));
      },
    };
  }

  return searchClient;
}

const defaultFacets = ["query", "page", "refinementList", "range", "sortBy"];

export default function ({
  indexName,
  algoliaAppId,
  algoliaKey,
  router = null,
  facets = defaultFacets,
  algoliaDefaultSortProductsIndex,
  bugsnag,
}) {
  return {
    data() {
      // Create it in `data` to access the Vue Router
      let options = {
        searchClient: getAlgoliaClient(algoliaAppId, algoliaKey, bugsnag),
        indexName,
      };

      if (router) {
        const { facet, facetValue } = router.currentRoute.params;
        const facetParams = { [facet]: facetValue };
        options = {
          ...options,
          routing: {
            router: nuxtRouter(router),
            stateMapping: getStateMapping(
              indexName,
              facets,
              facetParams,
              algoliaDefaultSortProductsIndex
            ),
          },
        };
      }

      const mixin = createServerRootMixin(options);

      return { ...mixin.data() };
    },
  };
}
