export default async function ({
  $config,
  redirect,
  route,
  $prismicApi,
  $axios,
}) {
  const currentMasterRef = $prismicApi.getMasterRef();
  const freshMasterRef = await $axios.get($config.prismicAPIEndPoint);
  if (process.client && currentMasterRef !== freshMasterRef.data.refs[0].ref) {
    redirect($config.appEndPoint + route.fullPath);
  }
}
